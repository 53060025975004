@import 'variables';

// note that we're styling an svg here
.print-card {
  font-family:"ProximaNova";
  width:#{calc(485 / $fontSize)}rem;
  height:#{calc(306 / $fontSize)}rem;

  .name, .crm, .since  {
    text-transform:uppercase;
    font-weight:800;
    font-style:italic;
  }

  &.member {
    .name, .crm {
      fill:$primaryBlue;
    }
  }

  &.blue {
    .name, .crm {
      fill:#FFFFFF;
    }
    .since {
      fill:$primaryBlue;
    }
  }

  &.gold {
    .name, .crm, .since {
      fill:#FFFFFF;
    }
  }

  &.life {
    .name, .crm, .since {
      fill:$primaryBlue;
    }
  }
}
