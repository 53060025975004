@import '../../components/sass/theme.module.scss';

//generic flow elements
.flow-container-w-margin {
  margin: 1rem;
  padding: 0;
}

.centered {
  text-align: center;
}

.apple-wallet {
  display: block;
  margin: 2rem auto;
  width: 10rem;
}

.calendar-button {
  text-align: center;
  width: fit-content;
  align-self: center;
  justify-self: center;
  padding-top: 0.65rem;
}

.flow-button-container > button {
  display: block;
  margin: 4rem auto;
}

.centered-button {
  display: block;
  margin: 0 auto;
}

.flow-inputs {
  display: flex;
  flex-direction: column;
  margin: 3rem auto;

  & > label {
    font-size: 0.9rem;
  }

  &-cc {
    display: flex;
    flex-direction: column;
    
    & > input {
      width: calc(100vw - 2.5rem);
    }
    & > label {
      font-size: 0.9rem;
    }
    
    &-row {
      display: flex;

      &:first-child {
        margin-right: 1rem;
      }

      .flow-inputs-cc > input {
        width: 70%;
      }
    }
  }
}

.flow-qr {
  width: 50vw;
  display: block;
  margin: 4rem auto;
}

//email link component
.email-container {
  padding:0;
  margin:0;
}

.email-heading {
  background-color: $guideGray;
  font-size: 1.5rem;
  padding: 1rem;
}

.email-heading, .email-text {
  text-align: center;
}

.email-button {
  display: block;
  margin: 0 auto 4rem;

}

//auction from email
.auction-email-container {
  top:0;
  height: 100vh;
  background-color: $primaryBlue;
  width: 100%;

  & > h1 {
    font-weight: 700;
    padding: 1rem;
    margin: 0;
    color: black;
    width: 100%;
    background-color: white;
  }
}
.auction-email-description {
  margin: 1.5rem;
  color: white;
  font-size: 1.5rem;

  & > a {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  & > span > a {
    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

.auction-email-banner {
  
  //16x9
  max-height: 360px;
  object-fit: contain;
  width: 100%;
  background-color: white;

  &-undefined {
    width: 100%;
    height: 200px;
    background-color: white;
    margin: 0 auto;
    padding: 15%;
    background-image: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.4) 100%);
    box-sizing: border-box;
  }
}

.landing-buttons-container {
  display: flex;
  flex-direction: column;
  border: 2px white solid;
  width: fit-content;
  margin: 2rem auto;
  
  & > button {
    width: fit-content;
    margin: 1rem auto;
  }

  & > p {
    color: white;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  
}

.auction-email-buttons {
  display: flex;
  flex-direction: column;

  & > button {
    width: fit-content;
    margin: 1rem auto;
  }

  & > .FilledBlueWhiteBorder {
    color: #FFF;
    background-color: transparent;
    border: 1.5px solid #FFF;

    /* hover, focus state */
    &:hover, &:focus {
      background-color: $secondaryMediumDarkBlue;
    }
  }
}

.auction-email-browse-link {
  display: flex;
  flex-direction: column;
  & > a {
    width: fit-content;
    margin: 0 auto;

    color: white;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

.success-check {
  margin: 4rem auto;
  display: block;
  width: 50%;
}

@media screen and (min-width: #{ $widthTablet }px) {
  .desktop-inputs {
    max-width: 25rem;
  }
}

@media #{$tablet} {
  .auction-email-description {
    font-size: 1.25rem;
  }
} 

@media #{$phone} {
  .auction-email-container {
    & > h1 {
      font-size: 1.5rem;
      line-height: 1.1;
    }
  }
  .auction-email-banner {
    width: 100%;
    height: calc(56.25vw)
  }
  .auction-email-description {
    font-size: 1.1rem;
  }
}