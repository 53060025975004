@import '../../components/sass/theme.module.scss';

.input-item {
  display: flex;
  flex-direction: column;
  margin: 1rem;

  & > input {
    width: 30rem;
  }
  & > select {
    width: 30.5rem;
    height: 2.2rem;
  }
  span {
    margin-top: .3rem;
    button {
      margin-right: .3rem;
    }
  }
}

.update-user-error {
  color: $messagingRed;
  margin-top: 1rem;
}
.update-user-success {
  color: $messagingGreen;
  margin-top: 1rem;
}

@media #{$phone} {

  .input-item {
    & > input {
      width: 95%;
    }
    & > select {
      width: 97.1%;
      height: 2rem;
    }
  }

  .update-user-error {
    color: $messagingRed;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .update-user-success {
    color: $messagingGreen;
    margin-top: 1rem;
    margin-left: 1rem;
  }
}