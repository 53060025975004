@import '../../components/sass/theme.module.scss';

.card-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  background-color: $lightGray;
  margin: 1rem 1rem;
  width: calc(100% - 2rem);
  border-radius: 3px;

  &:hover {
    box-shadow: 2px 2px 10px $buttonActiveBlue;        
  }
}

.card-image {
  
  & > img {
    position: relative;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.bid-selector-container {
  margin: 1rem;
}


.card-title {
  text-decoration: none;
  font-size: 1.4rem;
  justify-self: center;
  padding: 0.2rem 1rem;
  margin: 0 auto;
  height: 2.6em;
  line-height: 1.3em;

  &:hover {
    text-shadow: 2px 2px 10px #aaaaaad3;
    color: #2a72a8;    
  }

}

.card-description {
  padding: 0 1rem;
  max-height: 5.2em;
  line-height: 1.3em;
  position: relative;  

}

.place-bid-button {
  color: black;
  margin: 0 1rem;
  width: calc(100% - 2rem);
}

.favorite {
  display: flex;
  margin-left: 1rem;
  
  & > * {
    margin: 0;
    padding: 0;
  }
  & > img {
    width: 1.6rem;
  }
  & > p {
    margin-left: 1rem;
    color: $primaryBlue;
    font-weight: 700;
  }
}

.myitem {
  &-card {
    width: 20rem;
  }

  &-row {
    display: flex;
  }
}


//600 width + 2rem
@media screen and (max-width: 632px) {
  .card-image {
    //16x9
    height: calc((100vw - 2rem)*0.5625);
  }
}
//600 width + 2rem to tablet
@media screen and (min-width: 632px) and (max-width: #{ $widthTablet }px) {
  .card-image {
    //16x9
    height: 337.5px;
  }
}
// tablet and beyond
@media screen and (min-width: #{ $widthTablet }px) {
  .card-image {
    //16x9
    height: 11.25rem;
  }
}