@import '../../../../sass/variables.scss';

.callout {
    background-color: $primaryBlue;
    color: white;
    font-size: 0.5rem;
    font-weight: 700;
    padding: 0.05rem 0.15rem;
    border-radius: 4px;
    position: absolute;
    top: 8%;
    left: 92%;

    &.whitetext:hover {
        color: white;
        cursor: default;
    }
}


@media #{$tablet} {
    .callout {
        position: absolute;
        top: -12%;
        left: 3.5%;
    }
}