@import 'variables';

.pledges-page {
  padding: 0.5rem 1rem .5rem 2.2rem;
  max-width: 1600px;

  h1 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 2rem;
  }

  @media #{$tablet} {
    display: block;
  }

  @media #{$phone} {
    display: block;

    button {
      margin-right: 0;
    }
  }
}

.pledge-container {
  margin: 2rem 0;
}

.pledge-details-container {
  border:2px solid $borderGray;
  border-radius: 3px;
  padding: 2rem 2.5rem 1rem 3rem;

  h2 {
    color: #000000;
    font-size: #{calc(20/$fontSize)}rem;
    font-weight: 600;
    margin-top: 0;
  }

  @media #{$tablet} {
    display: block;
  }

  @media #{$phone} {
    display: block;
  }
}

.pledge-details {
  display: grid;
  grid-template-columns: 30% 25% 15% 25%;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  grid-column-gap: 2rem;

  h3 {
    color: #000000;
    font-size: #{calc(14/$fontSize)}rem;
    margin-top: .2rem;
    margin-bottom: .4rem;
  }

  p {
    color: #000000;
    font-size: #{calc(18/$fontSize)}rem;
    font-weight: 600;
    margin-top: 0;
  }

  @media #{$tablet} {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;

    div:nth-of-type(3) {
      order: 2;
    }
    div:nth-of-type(4) {
      order: 2;
    }
    div:nth-of-type(5) {
      order: 1;
    }
    div:nth-of-type(6) {
      order: 1;
    }
    div:nth-of-type(7) {
      order: 3;
    }
    div:nth-of-type(8) {
      order: 3;
    }
  }

  @media #{$phone} {
    display: block;
  }
}

.red-dot {
  color: red;
  font-size: #{calc(20/$fontSize)}rem;
}
.chartreuse-dot {
  color: chartreuse;
  font-size: #{calc(20/$fontSize)}rem;
}
.auto-payments {
  vertical-align: middle;
}

.header-button {
  margin-right: 2rem;
  margin-bottom: .5rem;
  width: #{calc(260/$fontSize)}rem;

  @media #{$phone} {
    width: 100%;
    &.first-header-button {
      margin-bottom: 1rem;
    }
  }
}

.pledge-details-footer {
  display: block;
  width: 100%;
  text-align: center;
  cursor:pointer;

  span {
    color: $primaryBlue;
    font-size: #{calc(14/$fontSize)}rem;
    font-weight: 600;

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: #{calc(16/$fontSize)}rem;
      width: #{calc(16/$fontSize)}rem;

      margin: 0 0.15rem 0.15rem 0.35rem;

      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('../../../../images/arrow-down-primary-blue.svg');
    }

    &.up-arrow {
      &:after {
        background-image: url('../../../../images/arrow-up-primary-blue.svg');
      }
    }
  }

  @media #{$tablet} {
    margin-top: 2.5rem;
  }

  @media #{$phone} {
    margin-top: 2rem;
  }
}

.payment-button {
  text-align: right;
  button {
    height: #{calc(42/$fontSize)}rem;
  }

  @media #{$phone} {
    width: 100%;
    float: none;
    display: block;
    top: 0rem;

    button {
      width:100%;
    }
  }
}

.pledge-regent-text {
  text-align: center;
}

.payment-history-outer-container {
  background:#EEF6FF;
  border:2px solid $borderGray;
  border-top: 0px;
  border-radius: 3px;
  padding: 2rem 1.2rem 1rem 1.2rem;
}

.payment-history-inner-container {
  background:#FFFFFF;
  border:1px solid $borderGray;
  border-radius: 3px;
  margin-bottom: 1rem;

  @media #{$tablet} {
    border: 0;
  }
}

.payment-history-label {
  font-size: #{calc(14/$fontSize)}rem;
  font-weight:600;
  color:$primaryBlue;
  text-transform:uppercase;
}

.payment-history-table {
  border-collapse: collapse;
  font-size:#{calc(14/$fontSize)}rem;
  line-height:#{calc( 20/14 )};
  width: 100%;

  th {
    border-bottom:2px solid $borderGray;
    padding: 1.5rem 1rem 1.5rem 1rem;

    &:first-child {
      width: 1rem;
      padding: 0;
    }
    &:last-child {
      width: 1rem;
      padding: 0;
    }
  }

  tbody tr td {
    border-bottom:1px solid $borderGray;
    padding: 1rem;

    &:first-child {
      width: 1rem;
      border: none;
      padding: 0;
    }
    &:last-child {
      width: 1rem;
      border: none;
      padding: 0;
    }

    &:nth-child(2) {
      white-space: nowrap;
    }
    &:nth-child(3) {
      white-space: nowrap;
    }
    &:nth-child(4) {
      padding-left: 1.5rem;
    }
    &:nth-child(5) {
      padding-left: 2.5rem;
      white-space: normal;
    }
    &:nth-child(6) {
      padding-left: 2rem;
    }
    &:nth-child(7) {
      padding-left: 3rem;
    }
    &:nth-child(8) {
      padding-left: 3rem;
    }
    &:nth-child(9) {
      padding-left: .5rem;
    }
    &:nth-child(10) {
      white-space: nowrap;
      padding-left: .5rem;
    }
  }

  tbody tr:last-child td {
    border-bottom:0;
  }


  @media #{$tablet} {
    display: none;
  }

  @media #{$phone} {
    display: none;
  }
}

.payment-history-mobile-and-tablet-container {
  display: none;

  background:#EEF6FF;

  @media #{$tablet} {
    display: block
  }

  @media #{$phone} {
    display: block;
  }
}

.payment-history-mobile-and-tablet-grid {
  background:#FFFFFF;
  border:1px solid $borderGray;
  border-radius: 3px;

  font-size:#{calc(14/$fontSize)}rem;
  line-height:#{calc( 20/14 )};
  margin: 0 0 2rem 0;

  > div {
    padding: 1rem;
    margin:0;

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  @media #{$tablet} {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    grid-template-rows: 33% 33% 34%;
    grid-auto-flow: row;

    > div {
      border-top: 1px solid $borderGray;
      border-left: 1px solid $borderGray;

      &:nth-child(1) {
        border-top: none;
        border-left: none;
      }
      &:nth-child(2) {
        border-top: none;
      }
      &:nth-child(3) {
        border-top: none;
      }
      &:nth-child(4) {
        border-left: none;
      }
      &:nth-child(5) {
      }
      &:nth-child(6) {
      }
      &:nth-child(7) {
        border-left: none;
      }
      &:nth-child(8) {
      }
      &:nth-child(9) {
      }
    }
  }

  @media #{$phone} {
    display: block;

    > div {
      border-bottom: 1px solid $borderGray;
      border-top: none;
      border-right: none;
      border-left: none;
      &:nth-child(9) {
        border: none;
      }
    }
  }
}

.tablet-only {
  display: none;

  @media #{$tablet} {
    display: block;
  }
  @media #{$phone} {
    display: none;
  }
}
