@import '../../sass/variables.scss';

a {
    text-decoration: none;
    font-weight: 700;
}

.blue-background {
  background-color: $primaryBlue;
  padding-bottom: 0.5rem;
}

.error-text {
  font-size: 0.9rem;
  color: $messagingRed;
}

.search-bar {
  display: flex;
  width: 16rem;
  justify-content: space-between;
}

.shadowed {
  box-shadow: 0px 2px 6px black;

  &-light {
    box-shadow: 0px 2px 6px $guideGray;
  }
}

.button {
  display: inline-flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  font-weight:600;
  text-transform: capitalize;
  text-decoration:none;
  white-space:nowrap;
  vertical-align:middle;
  cursor:pointer;
  height: 2.5rem;
  border: 1px solid #000000;
  color: #000000;
  background-color: transparent;
  border-radius:3px;
  box-shadow: 0px 2px 6px black;
}

.button:hover {
  transform: translateY(1px);
}

.donation-footer {
  background-color: $lightGray;
  position: relative;
  width: 50%;
  margin: 4rem auto;
  padding: 1rem;
  text-align: center;
  border: 1px solid $borderGray;

  @media #{$phone} {
    width: 100%;
    padding: 0;
    margin: 2rem 0 0 0;
  }
}

.centered-button {
  margin: 1rem auto;
  display: block;
}

/* These are the "official" variants used in the styleguide */

.FilledYellow {

  border-color: $primaryYellow;
  color: $secondaryMediumBlue;
  background-color: $primaryYellow;

  /* hover, focus state */
  &:hover, &:focus {
    border-color:$darkGold;
    color:$secondaryMediumBlue;
    background-color: $darkGold;
  }
}

.FilledBlue {
  color: #FFFFFF;
  border-color: $secondaryMediumDarkBlue;
  background-color: $secondaryMediumDarkBlue;

  /* hover, focus state */
  &:hover, &:focus {
    border-color: $primaryBlue;
    background-color: $primaryBlue;
  }
}

.FilledWhite {
  color: $primaryBlue;
  // border-color: $secondaryMediumDarkBlue;
  border: 2px solid $primaryBlue;
  background-color: #FFF;
  box-shadow: none;

  /* hover, focus state */
  &:hover, &:focus {
    border-color: $primaryBlue;
    background-color: $buttonActiveBlue;
    color: $primaryBlue;
  }
}

.StrokeYellow{
  border-color: $primaryYellow;
  color: $darkGold;

  /* hover, focus state */
  &:hover, &:focus {
    border-color:$primaryYellow;
    color: $secondaryMediumBlue;
    background-color: $primaryYellow;
  }
}

.StrokeBlue{
  border-color: $secondaryMediumDarkBlue;
  color: $secondaryMediumDarkBlue;

  /* hover, focus state */
  &:hover, &:focus {
    color: #FFFFFF;
    border-color:$secondaryMediumDarkBlue;
    background-color: $secondaryMediumDarkBlue;
  }
}

.StrokeRed{
  border-color: $errorRed;
  color: $errorRed;
  background-color: white;

  /* hover, focus state */
  &:hover, &:focus {
    border-color:$errorRed;
    color: white;
    background-color: $errorRed;
  }
}

.FilledBlueWhiteBorder {
  color: #FFF;
  background-color: transparent;
  border: 1.5px solid #FFF;

  /* hover, focus state */
  &:hover, &:focus {
    // border-color: $primaryBlue;
    background-color: $secondaryMediumDarkBlue;
  }
}

.boldGreen {
  color: $primaryDarkGreen;
  font-weight: 700;
}

// close button used in alerts
.CloseButton{
  display:block;
  padding: calc(10rem / $fontSize );
  border: none;
//   background: url('../../../images/icon-close.svg') no-repeat;
  background-size: contain;
  color: $darkGray;
}

.modal-background {
  position: fixed;
  top: 0;
  left:0;
  background-color: rgba(50, 50, 50, .2);
  z-index: 1010;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
}

.modal-card {
  z-index:1011;
  position: fixed;
  background-color: white;
  overflow-y: scroll;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 6px 3px 5rem $darkGray;
  top: 50%;
  height: fit-content;
  min-height: 160px;
  max-height:75vh;
  left: 50%;
  width: 40vw;
  min-width: 420px;
  transform: translate(-50%, -50%);

  & > p {
    text-align: center;
  }
}

.box-top-yellow-accent-wide, 
.box-top-yellow-accent-content {
  background-color: white;
  border-top: 3px solid $primaryYellow;
  box-sizing: border-box;
  padding: 0 1rem;
}

.box-top-green-accent-wide, 
.box-top-green-accent-content {
  background-color: white;
  border-top: 3px solid $messagingGreen;
  box-sizing: border-box;
  padding: 0 1rem;
}

.box-left-yellow-accent-wide, 
.box-left-yellow-accent-content {
  text-align: center;
  background-color: white;
  border-left: 3px solid $primaryYellow;
}

.box-top-yellow-accent-wide, 
.box-left-yellow-accent-wide,
.box-top-green-accent-wide,
.box-left-green-accent-wide  {
  margin: 1rem auto;
  width: calc(100% - 2rem);
  max-width: 600px;
}

.box-top-yellow-accent-content,
.box-left-yellow-accent-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
}


//data table display
.data-table {
  margin: 1rem;
  border: 2px solid black;
  border-spacing: 0;
  border-radius: 4px;
  font-size: 0.8rem;

  thead {
    background-color: white;
    color: black;
    font-weight: 700;
  }
  th {
    color: black;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid black;
  }
  th:last-child {
    border-right: none;
  }
  td {
    text-align: center;
    border-top: 1px black solid;
    margin: 1px;
    padding: 5px;
  }
}

.high-bid-notification, .outbid-notification {
  font-size: 0.8rem;
  font-weight: 700;
  width: fit-content;
  border-radius: 2rem;
  padding: 0rem 0.3rem;
}

.high-bid-notification {
  color: $primaryDarkGreen;
  border: 2px solid $primaryDarkGreen;
}

.outbid-notification {
  color: $invalidCardOrange;
  border: 2px solid $invalidCardOrange;
}

.hidden {
  display: none;
}

.outline-winning {
  outline: 3px solid #00DB6D;
}

.outline-outbid {
  outline: 3px solid $invalidCardOrange;
}

.contain-image {
  object-fit: contain !important;
}

//mobile settings for auction theme
@media #{$phone} {

    .search-bar {
      // padding: 1rem;
      width: 100%;

    }

    .donation-footer {
      width: 100%;
      padding: 0;
      margin: 2rem 0 0 0;

      & > p {
        padding: 0 0.5rem;
      }
    }

}

.truncate {
  
  &-twoline, &-fourline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  &-twoline {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }

  &-fourline {
    line-clamp: 4;
    -webkit-line-clamp: 4;
  }
}

.WideMobileButton {
  
  @media #{$phone} {
    width: 100%;
  }
}