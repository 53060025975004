@import '../../components/sass/theme.module.scss';

.signup-form {
  margin: 3rem;

  &-button-container {
    display: flex;
    width: 20rem;
    justify-content: space-between;
    margin-left: 1rem;
  }
}

.input-item {
  display: flex;
  flex-direction: column;
  margin: 1rem;

  & > input {
    width: 20rem;
  }
  span {
    margin-top: .3rem;
    button {
      margin-right: .3rem;
    }
  }
}

.input-checkbox-row {
  display: flex;
  flex-direction: row;
  width: 20rem;
  margin: 1rem;
  padding: 0;

  .checkbox-text {
    height: 1rem;
  }
}

.indicator-container {
  border-radius: 3px;
  background-color: $primaryYellow;
  display: flex;
  width: 10rem;
  justify-content: space-around;
}

.indicator-box-inactive, .indicator-box-active {
  width: 5rem;
  text-align: center;
  margin: 5px 0;
}

.indicator-box-active {
  background-color: $primaryBlue;
  color: white;
}
.indicator-box-inactive {
  background-color: $guideGray;
  color: black;
}

.indicator-text-container {
  display: flex;
  font-size: 0.7rem;
  width: 10rem;
  justify-content: space-evenly;
}

.paymentpage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5rem;

  &-leftside{
    width: 50%;
  }

  &-rightside{
    width: 30%;
    margin-top: 5rem;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .paymentpage-edit-icon {
    width: 2rem;
    height: 2rem;
  }
}

.update-user-error {
  color: $messagingRed;
  margin-top: 1rem;
}
.update-user-success {
  color: $messagingGreen;
  margin-top: 1rem;
}

@media #{$phone} {

  .signup-form {
    margin: 0 0 3rem 1rem;
  }

  .input-item {
    & > input {
      width: 97%;
    }
  }

  .your-information {
    margin-left: -1rem;
    
    h2 {
        margin-left: 1rem;
    }
  }

  .paymentpage {
    
    &-leftside{
      width: 115%;    
    }

    &-rightside{
      position: absolute;
      font-size: 0.6rem;
      right: 1.5rem;
      top: 0.5rem;
    }

    .paymentpage-edit-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .update-user-error {
    color: $messagingRed;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .update-user-success {
    color: $messagingGreen;
    margin-top: 1rem;
    margin-left: 1rem;
  }
}