@import 'variables';

.links {
  display:flex;
  justify-content:flex-start;
  list-style-type:none;
  padding:0;
  margin:0;

  @media #{$phone} {
    margin:#{calc(32 / $fontSizeMobile)}rem 0 0;
    display:block;
  }

  li {
    box-sizing:border-box;
    list-style-type:none;
    padding:0 #{calc(20 / $fontSize)}rem 0 0;
    margin:0;
    width:percentage(calc(500 / $compWidth));

    @media #{$phone} {
      width:100%;
      margin-bottom:#{calc(19 / $fontSizeMobile)}rem;
    }
  }

  address {
    line-height:1.3;
    @media #{$phone} {
      line-height:1.2;
    }
  }
}

.info {
  display:block;
  font-style:normal;
  font-size:#{calc(24 / $fontSize)}rem;

  @media #{$tablet} {
    font-size:#{calc(20 / $fontSize)}rem;
  }

  @media #{$phone} {
    font-size:#{calc(21 / $fontSizeMobile)}rem;
  }
}

.break {
  display:block;
}
