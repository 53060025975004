@import 'variables';

.additional-info-form {

  text-align:center;
  padding:0px;

  @media #{$phone}{
    padding:0;
    width:100%;
  }

  label {
    font-family: Arial;
    color: #666666;
    text-align:left;
    width:430px;
  }

  .dropdown-wrapper {
    div {
      line-height: 35px; // needed to get carat to line up
      height: 35px;
    }
  }

  .form-label {
    text-align:left;
    line-height:30px;
  }

  .form-input {
    height:30px
  }

  .form-submit-button {
    &:disabled {
      background-color: lightgrey;
      border: none;
    }
    &:enabled {
      cursor:pointer;
    }
  }
}

