@import '../../sass/variables.scss';

.title {
    margin-left: 2rem;
}

.copy {
    margin: 0 2.2rem 2rem;
    padding: 0;
}
.form-section {
    border: 1.5px solid $darkGray;
    border-radius: 5px;
    padding: 0 3.5rem;
    margin: 0 auto;
    width: 85%;
    margin-bottom: 2rem;
    
    .payment-form-input {
        height: 2.5rem;
        font-size: 1.4rem;
    }
}
.section-body {
    display: flex;
    position: relative;

    &__right {
        padding-left: 2.5rem;

        &-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
         
            & > h4 {
                margin: 0;
            }
        }
    }
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "ccname ccname number number"
        "date cvv postal postal";
    align-items: start;
    grid-gap: 1rem;
    margin-top: 1rem;

    &__entirefield {
        display: flex;
        flex-direction: column;

        &-ccname {
            grid-area: ccname;
        }
        &-number {
            grid-area: number;
        }
        &-date {
            grid-area: date;
        }
        &-cvv {
            grid-area: cvv;
        }
        &-postal {
            grid-area: postal;
        }   
    }   
}
.form-label {
    font-size: .9rem;
}
.form-button-container {
    margin: 2rem 0;
    display: flex;
    justify-content: end;

    &__submit {
        margin-left: 1.5rem;
    }
}
.hidden-warning {
    display: none;
}
.braintree-warnings {
    &__text {
        display: block;
        color: $errorRed;
    }
    &__input.payment-form-input {
        border: 1px solid $errorRed;
        position:relative;
    }
    &__generic {
        color: $errorRed;
        // text-align: left;
        margin: 0;
        padding-top: 0;
    }
}
.input-warning-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}
.braintree-warnings__generic {
    padding-top: 2rem;
}

//adjust the spacing and font size for buttons in tablet view
@media #{$tablet} {
    .form-label {
        font-size: 0.7rem;
    }
    .form-section {
        width: 92%;
        padding: 0 1.5rem;
    }
    .section-body {
        &__right {
                padding-left: 2rem;
        }
    }
    .braintree-warnings__text {
        font-size: 0.7rem;
    }
}

@media #{$phone} {
    .title {
        font-size: 2rem;
        margin-left: 1rem;
    }
    .form-section {
        border: none;
        padding: 0;
        margin: 0 auto;
        width: 85%;
        
        .payment-form-input {
            height: 2.5rem;
            font-size: 1.4rem;
        }
    }
    
    .section-body {
        display: flex;
        flex-direction: column;

        &__right {
            padding-left: 0rem;
    
            &-header {
                & > h4 {
                    margin-top: 2rem;
                    font-size: 2rem;
                }

                & > p {
                    display: none;
                }
            }
        }

        .card-valid {
            width: 60px;
        }
    }
    
    .form-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            "ccname"
            "number"
            "date"
            "cvv"
            "postal";
        align-items: center;
        grid-gap: 1.5rem;
        margin-top: 1.5rem;
    
        &__entirefield {
            display: flex;
            flex-direction: column;
    
            &-ccname {
                grid-area: ccname;
            }
            &-number {
                grid-area: number;
            }
            &-date {
                grid-area: date;
            }
            &-cvv {
                grid-area: cvv;
            }
            &-postal {
                grid-area: postal;
            }   
        }    
    }
    
    .form-label {
        font-size: 1.1rem;
    }
    .form-button-container {
        margin: 2rem 0;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
    
        &__back, 
        &__submit {
            margin: 0.7rem auto;
        }
    }
}