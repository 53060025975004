@import '../../../../sass/variables';

.bid-modal {

  &-container {
    z-index: 1008;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;

  }

  &-cancel {
    cursor: pointer;
  }

  &-content {
  z-index: 1070;
  position:fixed;
  top: calc((100vh - 309px) / 2);

  & > p {
    font-weight: 700;
    margin: 1rem;
  }

    &-header {
      font-size: 1.7rem;
    }

    &-title {
      color: $primaryBlue;
    }

    &-bid {
      font-size: 2rem;
    }

  }

  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    & > div {
      text-align:center;
      color: $primaryBlue;
      font-weight: 700;
    }

  }

  &-failed-header {
    display: flex;
    
    & > p {
      font-weight: 700;
    }
  }

  &-info-icon {
    width: 25px;
    margin: 1rem;
  }
}