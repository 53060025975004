@import '../../../../sass/_colors';
@import '../../../../sass/variables';

.modalInner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;

  .modal-text {
    padding: 0 10px;
    p {
      margin: 0;
      max-width: 450px;
    }
    .bold {
      margin-bottom: 10px;
    }
  }
}

.userCRMInformation {
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  background-color: $primaryBlue;
  min-height: calc(31rem / $fontSize);
  h6 {
    color :#fff;
    margin: 0 0 calc(18rem / $fontSize);
  }
  .crm-user-information {
    background-color: #FFFFC3;
  }
  .smaller-alert > div > div {
    padding: 0 calc(10rem/16);
  }
  .user-search-form {
    margin: 0;
  }
  .mismatch-error {
    color:#000000;

  }
  .link-user-info-block-title {
    font-size: calc(16rem / $fontSize);
  }
  .account-display {
    font-size: calc(14rem / $fontSize);
  }
}

.submitButtons {
  text-align: center;
  margin: calc(30rem / $fontSize);
  & > div {
    display: inline-block;
  }
  a {
    text-decoration: none;
  }
}

.userInfoDisplay {
  margin: calc(15rem / $fontSize) 0;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: calc(15rem / $fontSize);
  background-color: #FFFFC3;
  color: #000000;
  label {
    cursor:inherit;
  }
}


.eachUserLayout {
  display: flex;
  flex-wrap: wrap;
  margin: calc(23rem / $fontSize) 0 0;
  & > div {
    flex: 0 50%;
    margin: 0 0 calc(18rem / $fontSize);
    display: flex;
    flex-direction: column;
    label {
      font-size: calc(12rem / $fontSize);
    }
    & label:nth-child(2) {
      font-size: calc(14rem / $fontSize);
    }
  }
}

.userInfocontainer {
  position: relative;
}

.userInfoDisplay {
  margin: calc(15rem / $fontSize) 0;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: calc(15rem / $fontSize);
  background-color: #FFFFC3;
  color: #000000;
  label {
    cursor:inherit;
  }
}

.userInfoDisplayHeader {
  display:flex;
  justify-content:space-between;

  label{
    font-size: calc(14rem / $fontSize);
    color: $primaryBlue;
  }
  .account-linked{
    color: $guideGreen;
  }
  &:after{
    content: '';
    position: absolute;
    display: block;
    height: calc(1rem / $fontSize);
    width: 100%;
    top: calc(50rem / $fontSize);
    left: 0;
    background-color: $primaryBlue;
  }
  .linked-highlight{
    margin:0;
    img{
      margin-right: calc(5rem / $fontSize);
    }
  }
}

.crmUserLayout {
  margin: calc(15rem / $fontSize) 0;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: calc(15rem / $fontSize);
  background-color: #FFFFC3;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 0 50%;
    margin: 0 0 calc(18rem / $fontSize);
    display: flex;
    flex-direction: column;
  }
}

.backwardLinks {
  a {
    text-decoration: none;
  }
  a:nth-child(2) {
    align-items: flex-end;
  }
}
