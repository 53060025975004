@import '../../../sass/variables';
@import '../../../sass/global.scss';

$inputBackgroundGrey: #f6f6f6;
$inputBorderGrey: #dedede;

// import {device} from 'common/Device'

// import iconClear from 'images/x.svg';

// const defaultFontSize=16;
// $fontSize = 16

.input {
    border-radius: 3px;
    background-color: $inputBackgroundGrey;
    height: 4rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $inputBorderGrey;
    font-size: 1rem;
    // padding: 0 ${props => props.ExtraPaddingInput ? '50px' :'15px'};
    padding: 0 1rem;
    outline:none;

    ::placeholder{
        color:#9B9B9B;
    }

    // cursor:${props=>props.readOnly?"default":"text"};
    cursor: text;

    // @media ${device.mobile}{
    //     width:100%;
    // }
}


// .clearable-container {
//     position: relative;
//     overflow: hidden;
//     display: inline-block;
//     height: 3.125rem;
//     //styled component width had props
//     width: 100%;

//     input {
//         padding-right: 40px;
//         height: 100%;
//         width: 100%;

//         border: 1px solid ${props => props.errorOutline ? colors.guideRed : "#dedede"};

//         &:focus + button {
//         /* box-shadow: none; */
//         }
//     }

//     button {
        
//         // visibility: ${props => props.clearVisible ? "visible" : "hidden"};

//         position: absolute;
//         top: 0;
//         right: 0;

//         height: 100%;
//         width: 40px;

//         padding: 0;
//         /* box-shadow: -10px 0px 10px 1px #fff; */
//         box-shadow: none;

//         background: url(${iconClear}) no-repeat;
//         background-position: 40% 50%;

//         outline: none;
//         border: none;

//         cursor: pointer;
//     }
// }
/*
export class ClearableInput extends Component {

  state = {
    value: ''
  }

  handleChange = (e) => {
    this.setState({value: e.target.value});
    if (this.props.onChange) this.props.onChange(e);
  }

  handleClear = (e) => {
    e.preventDefault();
    this.setState({value: ''});
    if (this.props.onChange) this.props.onChange(e);
  }

  render() {
    return (
      <ClearableContainer
        className={this.props.className}
        clearVisible={this.state.value !== ''}
        width={this.props.width}
        errorOutline={this.props.errorOutline}
      >
        <Input {...this.props} value={this.state.value} onChange={this.handleChange}/>
        <button type="button" onClick={this.handleClear}></button>
      </ClearableContainer>
    )
  }
}
*/

.text-area {
    width: 100%; // styled component had props
    // height: ${props=>props.height||""}; // styled component had props
    border: 1px solid #FFFFFF; // styled component had props
    border-radius: 3px;
    box-sizing: border-box;
    padding: 1rem;
    resize: none;
    outline:none;

    :focus{
        border: 1px solid $primaryBlue;
    }
}