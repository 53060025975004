@import '../../../../sass/variables';

.formStyles {
  box-sizing:content-box;
  padding-bottom: 15px;
  form {
    padding-right: 20px;
    border-radius:calc(5rem / $fontSize);
  }
  button[type="submit"] {
    margin-right:calc(25rem / $fontSize);
  }
  .alert>div>div {
    padding-left: 0;
  }
}

.formWrapper {
  color:#fff;
  padding:5px 0 1px 15px;
  border-radius:5px;
  margin-top: 5px;
  background-color:$primaryBlue;
  div {
    &:first-child {
      width: 100%;
    }
  }
}

.helperText {
  ol li span {
    white-space: nowrap;
  }
}
