@import 'variables';
.container {
  display:flex;
  justify-content:space-between;
  background:$primaryBlue;
  padding: 0 0 0 #{calc(36 / $fontSize)}rem;
  font-weight:800;
  position:relative;
  z-index:100;

  @media #{$tablet} {
    padding-left:percentage(calc(15 / 320));
  }

  a {
    display:block;
  }
}
.return-link {
  position:relative;
  text-decoration:none;
  padding:#{calc(3 / $fontSize)}rem 0 #{calc(3 / $fontSize)}rem #{calc(31 / $fontSize)}rem;
  color:#FFFFFF;

  &:hover, &:focus {
    text-decoration:underline;
    color:#FFFFFF;
  }

  &:before, &:after {
    display:block;
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
  }

  &:before {
    content:'';
    border-radius:50%;
    border:1px solid #FFFFFF;
    height:#{calc(22 / $fontSize)}rem;
    width:#{calc(22 / $fontSize)}rem;
  }

  &:after {
    content:'';
    border-top:1px solid #FFFFFF;
    border-left:1px solid #FFFFFF;
    transform:translate(-20%, -50%) rotate(-45deg);
    height:#{calc(8 / $fontSize)}rem;
    width:#{calc(8 / $fontSize)}rem;
    top:50%;
    left:#{calc(11 / $fontSize)}rem;
  }
}
