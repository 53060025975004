@import '../../components/sass/theme.module.scss';

.countdown-container{
  margin: 1rem;
  width: 600px;
  // background-color: black;

  & > p {
  margin: 0.3rem 0 0;
  width: fit-content;
  // width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: $primaryYellow;
  font-weight: 700;  
  }
}

.auction-amount-due {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;

  &-label {
    margin: 0;
  }
}

.auction-ended-paiditems{
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-img {
    width: 4rem;
  }

  &-message {
    font-weight: 700;
    font-size: 1.1rem;
  }
}

.auction-description {
  margin: 0;
  padding: 0 1rem;
  color: white;

  &-unreg, &-browse, &-preview {
    flex: none;
    margin: 0.5rem;
    padding: 0 1rem;
    color: white;
    white-space: pre-line;

    & > a, & > span > a {
      color: white;
      &:visited {
        color: white;
      }
      &:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }

  &-unreg {
    margin-top: 2rem;
  }

  &-preview {
    text-align: left;
    width: 75%;
    padding-left: 5rem;
  }
}

.auction-description-inner {
  & > a {
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

.auction-row {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  background-color: $lightGray;
  margin-top: 1rem;
  border: 1px solid $borderGray;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-content {
      width: 33%;

      & > p {
        text-align: right;
      }
      & > button { 
        display: block;
        margin: 0 auto;
      }
    }

    &-link > a {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &:hover {
      background-color: $lightGray;
  }
}

.auction-list-container {
  margin: 1rem;
}

.auction-container {
  margin: 0;

  & > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > h2 {
    font-weight: 700;
    margin-left: 1rem;
  }
}

.auction-unregister-container {
  & > div {
    background-color: white;
    padding: 2rem;
    border-radius: 3px;

    & > p {
      color: $messagingRed;
      font-weight: 700;
      text-align: center;
    }
  }
}

.auction-banner {
  object-fit: cover;
  height: 100px;
  width: 100%;
}

.time-notice {
  color: $messagingRed;
  padding-left: 1rem;
}

.auction-link-button {
  width: 30%;
  display: block;
  margin: 0 auto 1rem;
}

.sim-button {
  margin: 2rem;
}

button.button-to-checkout {
  color: black;
  display: block;
  width: calc(100% - 1.5rem);
  margin: 1.5rem auto;
}

@media screen and (min-width: #{ $widthTablet }px) and (max-width: #{ $widthDesktop }px) {
  .auctionitems-container {
    display: flex;
    width: 45rem;
    justify-content: center;
    flex-wrap: wrap;
    
    & > div {
      width: 20rem;
    }
  }
}

@media screen and (min-width: #{ $widthDesktop }px) {
  .auctionitems-container {
    display: flex;
    width: calc(100vw - 1rem);
    max-width: 90rem;
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      width: 20rem;
    }
  }
}

@media #{$phone} {

  .auction-banner {
    height: 66.66vw;
  }

  .auction-row {
    padding: 0 1rem;
    border-radius: 3px;
    background-color: $lightGray;
    margin-top: 1rem;
    border: 1px solid $borderGray;
  
    &-top {
      flex-direction: column;

      &-content {
        width: 100%;

        & > p {
          text-align: center;
        }
      }

      & > div, & > p, & > button {
        margin: 0.3rem;
      }

      &-link > a {
        flex-direction: column;
        text-align: center;
      }
    }
  
    &:hover {
      background-color: $borderGray;
    }
  }

  .time-notice {
    padding-left: 0rem;
  }

  .auction-link-button {
    display: block;
    width: 80%;
    margin: 0 auto 1rem;

  }
  .countdown-container{
    width: calc(100vw - 2rem);
  }
}