@import '../../sass/variables.scss';

//button
.gifts-associated {

    [class*='__arrow-'] {
        display: inline-block;
        vertical-align: middle;
        height: calc(5rem / $fontSize);
        width: calc(8rem / $fontSize);
        margin:0 0.45rem;
        background-size:100% auto;
        background-repeat:no-repeat;
        background-position:50% 50%;
    }
      &__arrow-up {
          background-image:url('../../../../images/arrow-up-primary-blue.svg');
      }
      &__arrow-down {
          background-image:url('../../../../images/arrow-down-primary-blue.svg');
      }
      &:hover &__arrow-up {
          background-image:url('../../../../images/arrow-up-white.svg');
      }
      &:hover &__arrow-down {
          background-image:url('../../../../images/arrow-down-white.svg');
      }
}
//grid display
.gift-grid {
    &__container {
        background-color: $buttonActiveBlue;
        margin-left: -4.03rem;
        padding: 1rem 1rem 1rem 4rem;
        margin-bottom: 2rem;
    }
    display: grid;
    grid-template-columns: 7fr 2fr 2fr 2fr;
    grid-auto-rows: minmax(2px, auto);
    grid-template-areas:        "header-1 header-2 header-3 header-4"
                                "data-1 data-2 data-3 data-4";
    align-items: center;
    
    @for $i from 1 through 4 {
        &__col-header-#{$i} {
            grid-area: header-#{$i};
        }
        &__col-data-#{$i} {
            grid-area: data-#{$i};
        }
    }
}

[class*="gift-grid__col-header-"] {
    font-size: .8rem;
}
[class*="gift-grid__col-data-"] {
    font-weight: 700;
}
[class*="gift-grid__col-header-"] {
    
    &:not(.gift-grid__col-headers-0) {
        display: none
    }
}

@media #{$phone} {

    .gift-section-header {
        text-align: center;
    }

    //grid display
    .gift-grid {
        &__container {
            background-color: transparent;
            padding: 0rem;
            margin: 0;
        }

        background-color: $buttonActiveBlue;
        grid-template-columns: 100%;
        grid-auto-rows: minmax(2rem, auto);
        border-radius: 5px;
        padding: 1rem;
        padding-left: 1rem;
        grid-template-areas:    "header-1" 
                                "data-1"
                                "header-2" 
                                "data-2"
                                "header-3" 
                                "data-3"
                                "header-4" 
                                "data-4";

        margin-bottom: 1rem;
                                
        [class*="gift-grid__col-"] {
            padding-left: 1.5rem;
        }                     
    }

    [class*="gift-grid__col-header-"] {
        // color: blue;
        &:not(.gift-grid__col-headers-0) {
            display: block;
        }
    }
}