@import '../../../components/sass/theme.module.scss';

.live-auction {

  &-container {
    padding: 1rem;
  }
  &-summary{
    display: flex;
    justify-content: space-around;
    font-weight: 700;
  }
  &-charge-selected{
    border: 1px solid black;
    border-radius: 3px;
    width: fit-content;
    padding: 1rem;
    & > p {
      font-weight: 700;
      padding: 0 0 0 1rem;
      margin: 0;
    }
    & > button {
      margin: 1rem;
    }
  }
  &-attendee-filters{
    display: flex;
    margin-top: 2rem;
    align-items: flex-end;
    justify-content: space-between;

  }
  &-filter{
    width: 17rem;
    & > input, & > select {
      width: 12rem;
      height: 1.6rem;
    }
  }

  &-clipboardbutton {
    display: block;
    margin: 2rem auto 0;
  }
}

.admin-container {
  margin: 1rem;
  font-size: 0.8rem;
}

.admin-info-modal {
  &-header {
    font-weight: 700;
    text-align: center;
  }
  &-link {
    color: $primaryBlue;
    text-decoration: underline;
    border: none;
    background: transparent;
    font-weight: 700;
    font-size: 0.8rem;

    &:hover {
      color: $secondaryDarkBlue;
    }
  }

}

.above-fmv {
  font-weight: 700;
  color: white;
  background: rgb(39, 160, 116, 0.7);
}

@media #{$phone} {
  

}
