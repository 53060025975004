.label {
  display:block;
  font-style:normal;
  color:$primaryBlue;
  font-weight:800;
  text-transform:uppercase;
  line-height:1.3;

  @media #{$tablet} {
    font-size:#{calc(14 / $fontSize)}rem;
  }

  @media #{$phone} {
    font-size:#{calc(16 / $fontSize)}rem;
  }
}
