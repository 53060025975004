@import 'variables';
.alumni-email {

  @media #{$tablet} {
    button {
      display:flex;
      width:100%;
    }
  }

  label {
    display:block;
    font-weight:800;
  }

  input {
    margin:#{calc(4 / $fontSize)}rem 0;
  }

  .password {
    label, input {
      display:inline-block;
      vertical-align:middle;
    }
  }

  .input {
    display:block;
    width:#{calc(840 / $fontSize)}rem;
    box-sizing:border-box;

    @media #{$tablet} {
      width:100%;
    }
  }

  .show-password {
    margin-right:#{calc(6 / $fontSize)}rem;
  }

  span {
    display:block;
  }
}

.spacer {
  margin-top:#{calc(28 / $fontSize)}rem;

  @media #{$tablet} {
    margin-top:#{calc(16 / $fontSize)}rem;
  }
}
