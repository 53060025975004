@import 'variables';

.pay-pledges-page {
  padding: 0.5rem 1rem .5rem 2.2rem;
  max-width: 1600px;

  h1 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 2.5rem;
  }

  @media #{$tablet} {
    display: block;
  }

  @media #{$phone} {
    display: block;
    padding: 0.5rem 0 0 0;
    padding-bottom: 0;

    h1 {
      margin-bottom: 0;
      margin-left: 1rem;
    }
    p {
      margin-bottom: 2.5rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    button {
      margin-right: 0;
    }
  }
}

.view-open-pledges-button {
  width: #{calc(260/$fontSize)}rem;
  height: #{calc(42/$fontSize)}rem;
  border-width: 2px;

  @media #{$phone} {
    width: 90%;
    margin-left: 1rem;
  }
}

.pay-pledges-outer-container {
  background:#EEF6FF;
  border:2px solid $borderGray;
  border-radius: 3px;
  padding: 2rem 1.2rem 1rem 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media #{$tablet} {
    background: #FFFFFF;
  }
  @media #{$phone} {
    background: #EEF6FF;
    border: 0;
    border-right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.pay-pledges-inner-container {
  background:#FFFFFF;
  border:1px transparent;
  border-radius: 3px;
  margin-bottom: 1rem;

  @media #{$tablet} {
    border: 0;
  }
  @media #{$phone} {
    background:#EEF6FF;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.pay-pledges-desktop-table {
  border-collapse: collapse;
  width: 100%;

  th {
    color: $darkGray;
    font-weight: bold;
    font-size: #{calc(14/$fontSize)}rem;
    border-bottom:1px solid $borderGray;
    padding: 1.5rem 1rem 1.5rem 1rem;
    margin: 0;
    white-space: nowrap;
    text-align: left;

    @media screen and (max-width: 1200px) {
      white-space: normal;
    }

    // spacer
    &:first-child {
      padding: 0;
    }
    &:last-child {
      width: 1rem;
      padding: 0;
    }
  }
  td {
    border-bottom:1px solid $borderGray;
    padding: 1rem;
    white-space: nowrap;
    color: $darkGray;
    font-weight: bold;
    font-size: #{calc(14/$fontSize)}rem;
    margin-top: .2rem;
    margin-bottom: .4rem;
    vertical-align:top;

   &:first-child {
      width: 1rem;
      border: none;
      padding: 0;
    }
    &:last-child {
      width: 1rem;
      border: none;
      padding: 0;
    }
    &:nth-child(2) {
      font-weight: normal;
    }
    &:nth-child(3) {
      white-space: normal;
    }
    &:nth-child(6) {
      text-align: left;
      font-weight: normal;
    }
  }
}

.payment-input {
  min-width: 17rem;
  color:$darkGray;
  font-weight:500;
  font-size: #{calc(16/$fontSize)}rem;

  div:first-child {
    width: 100%;
    background-color: #fafafa;
    vertical-align:middle;
    height: #{calc(40/$fontSize)}rem;
    border-color: #B3B3B3;
    div {
      background-color: #fafafa;
      label {
        padding-right:#{calc(10/$fontSize)}rem;
        text-align: right;
        color: $darkGray !important;
      }
      img {
        padding-bottom: .6rem;
      }
    }
  }

  @media #{$tabletonly} {
    width: unset;
    display: flex;
    justify-content: flex-end;
    font-size: #{calc(16/$fontSize)}rem;
    padding-top: 1.5rem;

    div:first-child {
      background-color: #fafafa;
      height: #{calc(40/$fontSize)}rem;
      margin-right: 1rem;
      width: 16rem;

      div {
        background-color: #fafafa;
        line-height:38px;
        label {
          font-weight:500;
          color: $darkGray !important;
        }
        img {
          padding-right: .5rem;
          padding-left: .5rem;
          padding-bottom: .12rem;
        }
      }
    }
  }
  @media #{$phone} {
    width: unset;
  }
}

.pledge-pay-currency-input {
  padding-right: 10px;

  input {
    width: 100%;
    margin-top: 1rem;
    text-align: right;
    height: 2.39rem;
    border: 1px solid #B3B3B3;
    border-radius: 3px;
    padding-right: 8px;

    &:disabled {
      color: #000000;
      background-color: #e5e5e5;
    }

    &::placeholder {
      font-size: #{calc(16/$fontSize)}rem;

      @media #{$tablet} {
        font-size: #{calc(12/$fontSize)}rem;
      }
      @media #{$phone} {
        font-size: #{calc(16/$fontSize)}rem;
      }
    }
  }

  @media #{$tabletonly} {
    padding-right: 0;
    input {
      margin-top: 0;
      width: 8rem;
    }
  }
}

.pay-pledges-mobile-and-tablet-container {
  display: none;

  @media #{$tablet} {
    display: block
  }

  @media #{$phone} {
    display: block;
  }
}

.pay-pledge-mobile-and-tablet-grid {
  background:#FFFFFF;
  border:1px solid $borderGray;
  border-radius: 3px;
  font-size:#{calc(14/$fontSize)}rem;
  margin-bottom: 1rem;

  > div {
    color:$darkGray;
    padding: 1rem;
    margin:0;
    font-weight: 600;

    h3 {
      font-size: #{calc(14/$fontSize)}rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }

  @media #{$tablet} {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    grid-template-rows: auto auto auto;
    grid-auto-flow: row;

    > div {
      border-bottom: 1px solid $borderGray;
      border-left: 1px solid $borderGray;
      border-top: none;
      border-right: none;

      &:nth-child(1) {
        font-weight: normal;
        border-left: none;
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
      }
      &:nth-child(4) {
        border-left: none;
      }
      &:nth-child(5) {
        grid-column: 2 / 4;
        border-bottom: none;
        padding: 1.5rem;
      }
    }
  }

  @media #{$phone} {
    display: block;

    > div {
      border-bottom: 1px solid $borderGray;
      border-top: none;
      border-right: none;
      border-left: none;
      &:nth-child(9) {
        border: none;
      }
      h3 {
        color:$darkGray;
      }
    }
  }
}

.pay-pledges-payment-total {
  text-align: right;
  margin: 1rem 2rem;
  padding-bottom: 2rem;

  h3 {
    color: $darkGray;
    font-weight: bold;
    font-size: #{calc(14/$fontSize)}rem;
    padding-top: .5rem;
    margin-bottom: 0;
  }
  h2 {
    color: black;
    font-weight: bold;
    font-size: #{calc(24/$fontSize)}rem;
    margin: .4rem 0 1.5rem;
  }

  @media #{$phone} {
    background-color: #FFFFFF;
    padding: 1rem 1.5rem;
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

.pay-now-button {
  width: #{calc(160/$fontSize)}rem;
  height: #{calc(42/$fontSize)}rem;
  @media #{$phone} {
    width: 100%;
  }
}


