@import '../../../../sass/variables';

.qr {
  
  &-image { 
    display: block;
    margin: 2rem auto;
    width: 12rem;
  }

  &-error-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    box-sizing: border-box;
    padding: 0.5rem;
    text-align: center;
    margin: 2rem auto;
    width: 12rem;
    height: 12rem;
    color: $messagingRed;
    font-weight: 700;
    background-color: $guideGray;
    border-radius: 3px;
  }

}