@import '../../sass/variables.scss';

//CARD IMAGE FOR EACH PAYMENT METHOD 
.card-left > p {
    margin: 0;
    padding: 0;
}
.card-left__header {
    margin-bottom: 1rem;
}
.card-valid, .card-invalid {
    width: 22rem;
    display: flex;
    padding: 2rem 0 2rem 2rem;
}
.card-info {
    font-size: #{calc(20 / $fontSize)}rem;
    line-height:2;
    font-weight:700;
}
.card-valid {
    border: 2px solid $secondaryDarkBlue;
    border-radius: 8px;
}
.card-invalid {
    border: 2px solid $invalidCardOrange;
    border-radius: 8px;
}
.card-identifier {
    color: white;
    text-align: center;
    margin-top: 1.5rem;
    
    &__img {
        width: 60px;
        height: 40px;
        border-radius: 3px;
    }
}

@media #{$tablet} {
    // card view on payment update form needed to be smaller to accomodate input fields 
    .payment-update-form__tablet-view {
        .card-left__header {
            font-size: #{calc(14 / $fontSize)}rem;
            margin-bottom: 0.75rem;
        }
        &.card-valid, &.card-invalid {
            max-width: 16rem;
            padding: 1rem 0 1rem 1rem;
            margin-top: 2.7rem;
        }
        .card-info {
            font-size: #{calc(14 / $fontSize)}rem;
        }
        .card-identifier {
            margin-left: 25%;
            
            &__img {
                width: 3rem;
                height: 2rem;
                border-radius: 3px;
            }
        }
    }
}

@media #{$phone} {
    //payment form update specific
    .payment-update-form__tablet-view {    
        &.card-valid, &.card-invalid {
            margin-top: 0;
        }
    }
    //CARD IMAGE FOR EACH PAYMENT METHOD 
    .card-left__header {
        font-size: #{calc(14 / $fontSize)}rem;
        margin-bottom: 0.75rem;

    }
    .card-valid, .card-invalid {
        max-width: 16rem;
        padding: 1rem 0 1rem 1rem;
        // width: 21rem;
    }
    .card-info {
        font-size: #{calc(14 / $fontSize)}rem;
    }
    .card-identifier {
        margin-left: 25%;

        &__img {
            width: 3rem;
            height: 2rem;
            border-radius: 3px;
        }
    }
}