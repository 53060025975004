@import 'variables';

.alumni-wrapper {
  box-sizing:border-box;
  position:relative;
  width:100%;
  padding:percentage(calc($bannerHeight / $compWidth)) 0 #{calc(52 / $fontSize)}rem;
  max-width:none;

  // override base font color
  color:$reallyDarkGray;

  @media #{$phone} {
    padding:#{calc((181 + 16) / $fontSizeMobile)}rem 0 #{calc(52 / $fontSize)}rem;
  }

  &:before {
    content:'';
    position:absolute;
    width:100%;
    padding-bottom:percentage(calc($bannerHeight / $compWidth));
    background:url('../images/james-west-alumni-center.jpg') no-repeat;
    background-size:cover;
    background-position:50% 50%;
    top:0;
    left:0;

    @media #{$retina} {
      background-image:url('../images/james-west-alumni-center@2x.jpg');
    }

    @media #{$phone} {
      padding-bottom:#{calc(181 / $fontSizeMobile)}rem;
      background-image:url('../images/james-west-alumni-center-mobile@2x.jpg');
    }
  }
}

.content {
  box-sizing:border-box;
  max-width:#{$compWidth}px;
  padding:0 #{calc(36 / $fontSize)}rem;
  margin:0 auto;

  @media #{$tablet} {
    padding-left:percentage(calc(15 / 320));
    padding-right:percentage(calc(15 / 320));
  }

  @media #{$phone} {
    padding:0 percentage(calc(15 / 320));
  }
}

.membership-header {
  h2 {
    font-size:#{calc(36 / $fontSize)}rem;
    line-height:1.5;
    font-weight:800;
    margin-bottom:#{calc(20 / $fontSize)}rem;

    @media #{$phone} {
      font-size:#{calc(24 / $fontSizeMobile)}rem;
      margin-bottom:#{calc(6 / $fontSizeMobile)}rem;
    }
  }

  @media #{$phone} {
    button {
      display:flex;
      width:100%;
    }
  }
}

.molecules {
  margin-top:#{calc(48 / $fontSize)}rem;

  @media #{$phone} {
    margin-top:#{calc(32 / $fontSizeMobile)}rem;
  }

  .molecule {
    display:inline-block;
    background:url('../../../images/hexagon-yellow-simple.svg') no-repeat;
    background-size:contain;
    height:24px;
    width:24px;
    margin-right:12.5px;
  }
}

.multiple {
  margin-bottom:#{calc(72 / $fontSize)}rem;

  ul {
    list-style-position:inside;
    margin:0;
    padding:0;
  }

  li {
    font-weight:800;
  }
}
