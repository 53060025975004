@import '../../sass/variables';
// @import './colors';

.outer {
  padding: 16px;
  border: 1px solid rgb(232, 232, 232);
  width: 100%;
  display: inline-block;
  margin: 0px 0px 20px 0px;
}

.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #777;
  cursor: pointer;
  margin-bottom: 10px;

  button span {
    font-size:calc(14rem / $fontSize);
  }
}

.selectedFiles {
  h4 {
    margin-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ul {
    font-family: helvetica;
    margin-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

