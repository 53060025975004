.print-only {
  position:absolute;
  height:0;
  width:0;
  overflow:hidden;
}
@media print {
  .hide-on-print {
    display:none !important;
  }
  .print-only {
    height:auto;
    width:auto;
  }
}
