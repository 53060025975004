@import '../../../../sass/variables';

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  
  &__tab-list {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
    background: white;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: 4px $guideGray solid;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem 2.5rem;
    cursor: pointer;
    font-weight: 700;
    color: $reallyDarkGray;
    background-color: $lightGray;

    &--selected {
      // background: #fff;
      border: 1px solid $guideGray;
      border-bottom: 4px solid $primaryYellow;
      color: $primaryBlue;
      background-color: white;
      
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}