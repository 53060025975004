h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color:$primaryBlue;
  font-weight:400;

  // begin variants
  &.black {
    color:#000000;
  }

  &.dark-gray {
    color:$reallyDarkGray;
  }

  &.bold {
    font-weight:800;
  }

  &.with-line {
    &:before {
      content:'';
      display:inline-block;
      vertical-align:top;
      width:0.2em;
      height:1.3em;
      margin-right:#{ calc(13 / $fontSize) }rem;
    }

    &.line-yellow {
      &:before {
        background:$primaryYellow;
      }
    }
  }
}

/* sizing */
/* desktop */
h1, .h1 {
  font-size:calc(40rem/16);
  line-height:1.35;
}

h2, .h2 {
  font-size:calc(36rem/16);
  line-height:1.33;
}

h3, .h3 {
  font-size:calc(32rem/16);
  line-height:1.34375;
}

h4, .h4 {
  font-size:calc(24rem/16);
  line-height:1.375;
}


@media #{$phone} {
    .h4-mobile{
    font-size:calc(18rem/16) !important;
  }
}

h5, .h5 {
  font-size:calc(20rem/16);
  line-height:1.35;
}

h6, .h6 {
  font-size:calc(18rem/16);
  line-height:1.3889;
}

h3, .h3,h6, .h6{
  font-family:Helvetica,"Arial", Helvetica Neue,"HelveticaWeb", sans-serif;
}
h1.has-dots, h2.has-dots, h3.has-dots, h4.has-dots, h5.has-dots, h6.has-dots {
  font-weight:600;
}

h1.banner-header, h2.banner-header, h3.banner-header, h4.banner-header, h5.banner-header, h6.banner-header {
  color:#FFFFFF;
}


h6.has-no-margins,h5.has-no-margins,h4.has-no-margins,h3.has-no-margins,h2.has-no-margins,h1.has-no-margins{
  margin:0;
}

h1.has-dots:after, h2.has-dots:after, h3.has-dots:after, h4.has-dots:after, h5.has-dots:after, h6.has-dots:after {
 content:'';
 display:block;
 background:url('../images/MoreIcons.svg') no-repeat;
 background-position:center;
 height:calc(10rem/16);
 width:calc(50rem/16);
 margin:calc(12rem/16) auto 0;
}

h1 {

}

/* tablet */
@media #{$tablet} {
  /* headers */
  h1, .h1 {
    font-size:calc(34rem/16);
    line-height:1.206;
  }

  h2, .h2 {
    font-size:calc(32rem/16);
    line-height:1.1875;
  }

  h3, .h3 {
    font-size:calc(26rem/16);
    line-height:1.269;
  }

  h4, .h4 {
    font-size:calc(20rem/16);
    line-height:1.3;
  }

  h5, .h5 {
    font-size:calc(18rem/16);
    line-height:1.389;
  }

  h6, .h6 {
    font-size:1rem;
    line-height:1.1875;
  }

  h1.has-dots:after, h2.has-dots:after, h3.has-dots:after, h4.has-dots:after, h5.has-dots:after, h6.has-dots:after {
    margin-left:0;
    margin-right:0;
  }
}

/* mobile */
@media #{$phone} {
  /* headers */
  h1, .h1 {
    font-size:calc(26rem/16);
    line-height:1.1923;
  }

  h2, .h2 {
    font-size:calc(24rem/16);
    line-height:1.20833;
  }

  h3, .h3 {
    font-size:calc(20rem/16);
    line-height:1.2;
  }

  h4, .h4 {
    font-size:calc(18rem/16);
    line-height:1.2222;
  }

  h5, .h5 {
    font-size:1rem;
    line-height:1.1875;
  }

  h6, .h6 {
    font-size:calc(14rem/16);
    line-height:1.2143;
  }

  h1.has-dots, h2.has-dots, h3.has-dots, h4.has-dots, h5.has-dots, h6.has-dots {
    text-align:center;
  }

  h1.has-dots:after, h2.has-dots:after, h3.has-dots:after, h4.has-dots:after, h5.has-dots:after, h6.has-dots:after {
    margin-left:auto;
    margin-right:auto;
  }
}
