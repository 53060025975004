@import '../../components/sass/theme.module.scss';

.item-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.path-text {
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 700;
  display: block;
  text-align: center;
}

.item-page-path {
  display: flex;
  align-items: center;
  margin: .5rem 1rem;

  & > a, & > p {
    margin: 0;
    padding: 0;
  }
}

p.path-dash {
  margin: 0 0.5rem;
}

.item-page-img {
  //16x9
  width: 600px;
  height: 337.5px;
  object-fit: cover;
  margin: 2rem auto 0;
  border: 1px white solid;
}

.item-bottom-info {
  width: calc(100vw - 2.5rem);
  margin: 0 auto;
  margin-bottom: 2rem;
  max-width: 600px;
}

.item-info-outer {
  background-color: $primaryYellow;
  width: 100%;
}

.item-info-inner {
  background-color: white;
  padding: 0.7rem 0rem 0.7rem 1rem;
  margin-left: 3px;

  & > p {
    margin: 0;
    padding: 0;
    font-weight: 700;
  }
}

.item-description {
  color: white;

  & > a, & > span > a {
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

@media #{$phone} {

  .item-page-img {
    width: 100vw;
    height: 69vw;
    border: 0;
  }
}