@import '../../components/sass/theme.module.scss';

.admin-item {
  &-row {

    & > td > img {
      //16x9
      width: 2rem;
      height: 1.125rem;
      object-fit: cover;
      padding: 0 0.5rem;
    }

    &-actions {
      display: flex;
      align-items: center;

      & > button {
        margin: 0.5rem;
        border: none;
        background: none;
        text-decoration: underline;
        font-weight: 700;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }
  }
  &-edit-button {
    color: $primaryBlue;
  }
}