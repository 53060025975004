@import '../../components/sass/theme.module.scss';

.payment {
  
  &-container {
    // margin: 1rem;
    padding: 1rem;
  }

  &-header, &-header-white {
    font-weight: 700;
    font-size: 2rem;
    color: #000; 
    margin: 0rem auto;

    &-white {
      color: #FFF;
    }
  }

  &-text {
    font-size: 0.9rem;
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    & > label {
      font-size: 0.9rem;
    }
    & > input {
      background-color: $lightGray;
      border: 1px solid black;
      border-radius: 3px;
      padding: 0.35rem;
    }
    & > p {
      font-size: 0.75rem;
      color: $darkGray;
      margin: 0;
      padding-top: 0.4rem;
    }
  }

  &-add-button {
    display: block;
    margin: 0 auto;
  }

  &-confirm {
    display: block;
    margin: 3rem auto;
    width: 30%;
    max-width: 100px;
  }

  &-card-display{

    &-valid, &-expire {
    padding: 0 1rem;
    margin: 2rem 0;
    max-width: 18rem;
    border: 2.5px solid;
    border-radius: 3px;
    }

    &-valid {
      border-color: $secondaryBabyBlue;
    }

    &-expire {
      border-color: #FF6D38;
    }
  }

  &-expire-note {
    display: flex;

    & > img {
      width: 1rem;
      margin-right: 1rem;
    }
  }

  &-button {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > button {
        margin: 1.5rem;
      }
    }
    &-text {
      color: $secondaryMediumDarkBlue;
    }
  }
}

// .qr {

//   &-confirm {
//     &-row {
//       display: flex;
//       flex-direction: row;
//       margin: 1rem 0;
//     }

//     &-check {
//       width: 1.3rem;
//       padding-right: 1rem;
//     }

//     &-text {
//       color: $primaryDarkGreen;
//       font-weight: 700;
//       margin: 0;
//     }

//     &-wallet-button {
//       display: block;
//       margin: 2rem auto;
//       width: 10rem;

//       &-google {
//         width: 12rem;
//       }
//     }
//   }
// }

.auction-button {
  display: block;
  margin: 2rem auto;
}