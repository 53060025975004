@import 'variables';
.container {
  display:flex;
  flex-wrap:wrap;
  align-items:stretch;

  @media #{$phone} {
    display:block;
    padding:#{calc(16 / $fontSizeMobile)}rem 0 #{calc(4 / $fontSizeMobile)}rem;
  }
}
.cell {
  box-sizing:border-box;
  padding-right:1rem;
  flex:1 1 50%;
  margin-bottom:#{calc(46 / $fontSize)}rem;
  line-height:1.3;

  @media #{$phone} {
    padding:0;
    margin-bottom:#{calc(16 / $fontSizeMobile)}rem;
  }

  span {
    display:block;
    font-style:normal;
    font-size:#{calc(24 / $fontSize)}rem;
    line-height:1.2;

    @media #{$phone} {
      font-size:#{calc(21 / $fontSize)}rem;
    }
  }
}
.update {
  > * {
    margin-right:#{calc(44 / $fontSize)}rem;
  }

  @media #{$phone} {
    button {
      display:flex;
      width:100%;
      margin-bottom:#{calc(16 / $fontSizeMobile)}rem;
    }
  }

  p {
    margin-top:0;
    font-weight:800;
  }
}

.degree-list {
  list-style-type:none;
  margin:0;
  padding:0;

  li {
    font-style:normal;
    font-size:#{calc(24 / $fontSize)}rem;
    line-height:1.3;

    @media #{$phone} {
      font-size:#{calc(21 / $fontSize)}rem;
    }
  }
}
