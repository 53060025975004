@import '../../components/sass/theme.module.scss';

.qr {

  &-container-spacer {
    width: 100%;
    height: 2rem;
  }

  &-confirm {
    &-row {
      display: flex;
      flex-direction: row;
      margin: 1rem 0;
    }

    &-check {
      width: 1.3rem;
      padding-right: 1rem;
    }

    &-text {
      color: $primaryDarkGreen;
      font-weight: 700;
      margin: 0;
    }

    &-wallet-button {
      display: block;
      margin: 2rem auto;
      width: 10rem;

      &-google {
        width: 12rem;
      }
    }
  }
}