/* font declarations */
@font-face {
  font-family: "HelveticaWeb";
  src: url('../fonts/Helvetica.woff2') format("woff2"), url('../fonts/Helvetica.woff') format("woff");
  font-weight:400;
}

@font-face {
  font-family: "HelveticaWeb";
  src: url('../fonts/Helvetica-Oblique.woff2') format("woff2"), url('../fonts/Helvetica-Oblique.woff') format("woff");
  font-weight:400;
  font-style:italic;
}

@font-face {
  font-family: "HelveticaWeb";
  src: url('../fonts/Helvetica-Bold.woff2') format("woff2"), url('../fonts/Helvetica-Bold.woff') format("woff");
  font-weight:600;
}

@font-face {
  font-family: "HelveticaWeb";
  src: url('../fonts/Helvetica-BoldOblique.woff2') format("woff2"), url('../fonts/Helvetica-BoldOblique.woff') format("woff");
  font-style:italic;
  font-weight:600;
}

@font-face {
  font-family: "ProximaNova";
  src: url('../fonts/proximanova.woff') format("woff");
}

@font-face {
  font-family: "ProximaNova";
  src: url('../fonts/proximanova-bold.woff') format("woff");
  font-weight:800;
}

@font-face {
  font-family: "ProximaNova";
  src: url('../fonts/proximanova-italic.woff') format("woff");
  font-style:italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url('../fonts/proximanova-sbold.woff') format("woff");
  font-style:600;
}

@font-face {
  font-family: "ProximaNova";
  src: url('../fonts/proximanova-sboldit.woff') format("woff");
  font-style:italic;
  font-weight:600;
}

@font-face {
  font-family: "ProximaNova";
  src: url('../fonts/proximanova-boldit.woff') format("woff");
  font-style:italic;
  font-weight:800;
}

/* body copy */
body, .normal {
  font-family: Helvetica Neue,Helvetica,"HelveticaWeb","Arial", sans-serif;
  font-size:1rem;
  line-height:1.625;
}

input, select, textarea, button{font-family:inherit;}

small {
  font-size:calc(12rem/16);
  line-height:1.8333;
}

input, textarea, select, button {
  font-size:1rem;
  line-height:1.625;
}

/* links */
a {
  color:#2774AE;
}
a:hover {
  color:#2774AE;
}

/* alignment */
.center {
  text-align:center;
}

/* font style */
.bold{
  font-weight:600;
}

/* tablet */
@media #{$tablet} {
  body, .normal {
    font-size:1rem;
    line-height:1.4375;
  }

  small {
    font-size:calc(12rem/16);
    line-height:1.667;
  }

  input, textarea, select, button {
    font-size:1rem;
    line-height:1.4375;
  }

}
