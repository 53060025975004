html,body {
  margin: 0;
  padding: 0;
 min-height: 100%;
 /* background: #F6F6F6;   */
 background: #FFFFFF;
}
body{
  position:relative;
  min-width: 300px;
}

div#root {
  position: relative;
}

div.background-white{
  background: #FFFFFF;
}

div.background-grey{
  background: #F6F6F6;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
