@import '../../components/sass/theme.module.scss';

.auction-card {
  
  &-container {
    box-shadow: 0px 0px 10px $guideGray;
    margin-bottom: 2rem;
    border-radius: 4px;
    padding: 1rem 1rem 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    grid-auto-rows: minmax(32px, auto);
  }

  &-grid {

    &-image {
      margin: 0 auto;
      //16x9
      width: 10rem;
      height: 5.625rem;
      object-fit: contain;
      grid-column: 4;
      grid-row: 1;
    }

    &-status{
      margin: auto 0;
      grid-column: 3;
      grid-row: 1;
  
      &-kind, &-ended, &-live {
          padding: 0.3rem 0.7rem;
          color: white;
          font-weight: 700;
          font-size: 0.7rem;
          width: fit-content;
          margin: 0.2rem auto;
      }

      &-kind {
        background-color: $secondaryMediumDarkBlue;
      }
      &-ended {
        background-color: $primaryYellow;
        color: black;
      }
      &-live {
        background-color: $primaryDarkGreen;
      }
    }

    &-title {
      color: $primaryBlue;
      font-weight: 700;
      font-size: 2rem;
      grid-column: 1/3;
      text-align: center;
      margin: auto 0;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:hover {
        color: $secondaryMediumDarkBlue;
      }
    }

    &-buttons {
      grid-column: 1/5;
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;

      &-live > * {
        margin: 0 1rem;
      }
    }

    &-checklist {
      grid-column: 1/5;
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;

    }
  }

  &-info {
    text-align: center;
    margin: 2rem 0;
    
    & > p:first-child {
      font-weight: 700;
      padding: 0;
      margin: 0;
    }

    & > p:last-child {
      padding: 0;
      margin: 0;
    }
  }
}

.auction-status{

  &-kind, &-ended, &-live, &-closed {    
      padding: 0.3rem 0.7rem;
      color: white;
      font-weight: 700;
      font-size: 0.7rem;
      width: fit-content;
      margin: 0.2rem auto;
  }

  &-closed {
    background-color: $messagingRed;
  }
  &-kind {
    background-color: $secondaryMediumDarkBlue;
  }
  &-ended {
    background-color: $primaryYellow;
    color: black;
  }
  &-live {
    background-color: $primaryDarkGreen;
  }
}

.live-bid-link {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
}