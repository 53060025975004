@import '../../../components/sass/theme.module.scss';

.admin-container {
  margin: 1rem;
  table {
    border: 1px solid $guideGray;
    border-spacing: 0;
    border-radius: 4px;
  }
  td {
    border: 1px $borderGray solid;
    margin: 1px;
    padding: 5px;
    text-align: right;
  }
}


//auction and item pages

.row-container {
  margin-right: 1rem;
  position: relative;
}

.admin-auction-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
  border: 1px solid $guideGray;
}

.admin-auction-row:hover {
  background-color: $lightGray;
  border: 1px solid $guideGray;
}

.admin-auction-button-container {
  position: absolute;
  top: 2px;
  left: calc(100% - 6rem);
  display: flex;
  align-items: center;
  & > button {
    margin: 0.5rem;
  }
}

.button-edit {
  padding: 0.8rem;
}


.auction-modal {
  top: 20vh;
  height: 60vh;
  left: 20vw;
  width: 60vw;
  z-index:1011;
  position: fixed;
  background-color: white;
  overflow-y: scroll;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 6px 3px 5rem $darkGray;
}

.item-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.auction-select-list {
  list-style: none;
}

.auction-headers {
  font-weight: 700;
  color: $secondaryMediumDarkBlue;
}

.auction-blocks {
  margin-bottom: 4rem;
}