@import 'variables';

.alert-wrapper {
  box-sizing:border-box;
  margin:#{calc(64 / $fontSize)}rem auto;
  width:percentage(calc(1250 / $compWidth));
  max-width:1100px;

  @media #{$tablet} {
    margin-left:percentage(calc(15 / 320));
    margin-right:percentage(calc(15 / 320));
    width:auto;
  }

  @media #{$phone} {
    margin:#{(calc(16 / $fontSize))}rem percentage(calc(15 / 320)) #{(calc(32 / $fontSize))}rem;
  }
}

.user-not-found {
  margin-left:#{calc(27 / $fontSize)}rem;

  em {
    display:block;
    font-style:normal;
    font-weight:800;
    font-size:#{calc(24 / $fontSize)}rem;
    line-height:1.3;
    margin-bottom:#{calc(8 / $fontSize)}rem;

    @media #{$phone} {
      font-size:1rem;
      margin-bottom:#{calc(8 / $fontSize)}rem;
    }
  }

  p {
    font-style:normal;
    margin:#{calc(10 / $fontSize)}rem 0;

    @media #{$phone} {
      font-size:1rem;
    }
  }
}

.icon-info {
  display:block;
  position:absolute;
  left:#{calc(28 / $fontSize)}rem;
  top:50%;
  transform:translateY(-50%);
  background:url('../images/icon-alumni-info.svg') no-repeat;
  background-size:cover;
  height:#{calc(45 / $fontSize)}rem;
  width:#{calc(45 / $fontSize)}rem;

  @media #{$phone} {
    height:#{calc(36 / $fontSize)}rem;
    width:#{calc(36 / $fontSize)}rem;
    left:#{calc(18 / $fontSize)}rem;
  }
}
