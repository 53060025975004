@media print {
  .hide-on-print {
    display:none !important;
  }

  .modal-content {
    display:block;
    width:100%;
  }
}
