// this will eventually replace the styled components we use for buttons
.button {
  @include button-base();
  @include button-size();
  @include button-color();

  &.is-small {
    @include button-size('small');
  }

  &.is-blue {
    @include button-color($filledBlue);
  }

  &.is-yellow {
    @include button-color($filledYellow);
  }

}
