@import '../../components/sass/theme.module.scss';

.myitem {
  
  &-container {
    display: flex;
    flex-direction: column;
    width: 20rem;
    background-color: $lightGray;
    margin: 1rem 1rem;
    border-radius: 3px;
  
    &:hover {
      box-shadow: 2px 2px 10px $buttonActiveBlue;        
    }
  }

  &-title {
    color: white;
    position: absolute;
    z-index: 50;
    text-align: center;
  }

  &-bidinfo {

    & > p {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0 1rem;

      span {
        font-size: 1.3rem;
        padding-left: 0.5rem;
      }
    }
  }

  &-bidcount {
    color: $darkGray;
    font-weight: 700;
  }

  &-notification-row {
    height: 52px;
    & > p {
      margin: 0.8rem auto;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
  }

  &-view-link {
    font-weight: 700;
    font-size: 0.8rem;
    text-decoration: underline;
    margin-right: 1rem;

    &:hover {
      color: $secondaryMediumDarkBlue;
    }
  }
}

.myitem-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 4rem;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &::after, &:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; /* Ensures the image is still interactive */
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &::after {
    background: rgba(39, 116, 174, 0.6); /* Apply the tint */
  }

  &:hover::after {
    background: rgba(39, 116, 174, 0.85);
  }
}


@media #{$phone} {
  .myitem-container {
    width: calc(100% - 1rem);
    max-width: 20rem;
    margin: 1rem auto;

  }

  .myitem-image {
    width: 100%;
  }

}