@import 'variables';

.header-main {
  height:#{calc( 60 / $fontSize )}rem;

  @media #{$tablet} {
    height:auto;
    background:#FFFFFF;
  }
}
