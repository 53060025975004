@import '../../components/sass/theme.module.scss';

.page-container {
  margin: 1rem;
}

.edit-container {
  // padding: 0 1rem 1rem;
  width: calc(100% - 2rem);
  margin: 0 1rem;

  & > form > h2 {
    margin: 0;
  }
}

.asterisk {
  color: $messagingRed;
  font-size: 1rem;
  font-weight: 700;
}

.item-container {
  margin-left: 0rem;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.error-text {
  color: $messagingRed;
  font-size: 0.8rem;
  font-weight: 700;
  padding-left: 1rem;
}

.acceptable-text {
  font-size: 0.8rem;
  color: $darkGray;
}

p.acceptable-text {
  margin: 0 0 1rem 0;
  padding: 0 0 0 .4rem;
}

.create-item-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  &-checkin {
    border: 1px black solid;
    border-radius: 3px;
    padding: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    & > label {
      font-weight: 700;
    }
    & > select {
      width: 5rem;
      margin: 0.5rem 0;
    }
    & > p {
      font-size: 0.8rem;
      color: $darkGray;
      padding: 0;
      margin: 0;
    }
  }
}

.edit-item-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}

.success-container {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > p {
    padding-left: 2rem;
  }
}
.success-checkmark {
  width: 40px;
}

.label-notice {
  color: $messagingRed;
  font-size: 0.7rem;
}

span.label-notice {
  padding-left: 0.5rem;
}

p.label-notice {
  padding: 0;
  margin: 0;
}

.thumbnail-container {
  display: flex;
  & > img {
    //16x9
    width: 6rem;
    height: 3.375rem;
    object-fit: cover;
  }
}

.button-image-edit {
  margin: 1rem;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in number input fields for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.required-messages {
  & > p {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    font-weight: 700;
    color: $messagingRed;
  }

}

@media #{$phone} {
  
  .item-container {
    margin-left: 0;
  }
}