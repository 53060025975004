@import '../../components/sass/theme.module.scss';

.auction-box-info {

  &-heading {
    font-size: 1.1rem;
    font-weight: 700;
  }

  &-text {
    // padding-left: 1rem;

    &-smaller {
      font-size: 0.8rem;
    }
  }

  &-link {
    color: $primaryBlue;
    font-weight: 700;
    cursor: pointer;
  }

  &-icons {
    width: 1rem;
    padding-right: 1rem;
    cursor: pointer;
  }

  &-row {
    display: flex;

    & > button {
      margin: 0 auto;
      width: 100%;
    }
  }
}

.countdown {
  &-timer, &-heading {
    margin: 0;
    padding: 0.1rem 1rem
  }
  
  &-timer {
    font-size: 1.8rem;
    font-weight: 700;
  }
  
  &-heading {
    font-size: 0.8rem;
    font-weight: 700;
  }

  &-over {
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0.5rem 1rem
  }
}

.qr {
  
  &-container {
    background-color: white;
    width: calc(100% - 2rem);
    border: 2px solid $primaryYellow;
    max-width: 600px;
    box-sizing: border-box;
    padding: 0 1rem;
    margin: 0.5rem auto;
  }
  &-image { 
    display: block;
    margin: 2rem auto;
    width: 10rem;
  }

  &-link {
    font-size: 0.9rem;
    color: $primaryBlue;
    text-align: center;
    font-weight: 700;
  }
}

.modal-content {
  z-index: 1011;
  position: fixed;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
  top: 20vh;
  width: calc(100% - 4rem);

  & > * {
    margin: 1rem auto;
  }
}

// @media #{$phone} {
// }