@import 'variables';

.print-summaries-page {
  padding: 0.5rem 1rem .5rem 2.2rem;
  max-width: 680px;

  h1 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 2rem;
  }

  @media #{$tablet} {
    display: block;
  }

  @media #{$phone} {
    display: block;

    button {
      margin-right: 0;
    }
  }
}

.header-button {
  margin-right: 2rem;
  margin-bottom: .5rem;
  width: #{calc(300/$fontSize)}rem;

  @media #{$phone} {
    width: 100%;
    &.first-header-button {
      margin-bottom: 1rem;
    }
  }
}
