@import '_colors';
@import 'variables';

.modalInner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  img {
    height: 40px;
    width: 40px;
    padding: 0 10px;
  }
}

.modalText {
  font-size: calc(24rem / $fontSize);
  padding: 0 10px;
  font-weight: normal;
  margin: 0;
  textarea {
    margin-top:5px;
    font-size: 1rem;
    line-height:2;
  }
  p {
    color: $primaryBlue;
    font-size: calc(18rem / $fontSize);
  }
}

.rowIcon {
  cursor: pointer;
  vertical-align: middle;
  height: 20px;
  strong {
    font-size: 1.4rem;
  }
}

