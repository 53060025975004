@import '../../sass/variables.scss';

//converted style components from Reports_____ to .alertBanner_____ for easier future alert banners in the dashboard

.alertBannerIconContentContainer {
    display:flex;
    flex-direction:row;
    justify-content:center;

    /* tablet breakpoint */
    @media #{$tablet} {
        border-radius:0;
        justify-content: flex-start;
    }
  
    @media #{$phone} {
        align-items: flex-start;
        padding-right: 25px; /* keep  the close button from overlapping */
    }
}

.alertBannerMessage {

      p {
        margin:0 0 0.5rem;
        line-height:1.25;
      }
      @media #{$phone}  {
        padding:0 0 0 calc(15rem/16);
      }
}

.alertBannerGrid {
  // padding-right: calc(15rem/16);
  max-width: 1440px;


  //margin-top had props from ReportsGrid
  margin-top: 1rem;
  margin-bottom: 2rem;
  @media #{$tablet} {

    margin-top :0rem;
    margin-bottom: 0rem;
    width: 100%;
    max-width:100%;
    padding-top: 1rem;
    &.wrapper {
      padding:0;
    }
  }
  @media #{$phone} {
    margin-bottom: 0
  }
}

.alertBannerContainer {
    align-items:center;
    position:relative;
    z-index:2;
    overflow:hidden;
    -ms-grid-column-span: 12;
    grid-column-end: span 12;
    background-color: white;
    border-radius:calc(5rem / 16);
    cursor:default;
    padding: calc(23rem/16) 4.6875% calc(19rem / 16);
}

.alertBannerOuterDiv {
  position:absolute;
  right:70px;
  top:37px;
  button {
    margin:0 auto;
  }
  @media #{$phone} {
    right:20px;
    top:20px;
  }
}

.alertBannerIcon {
  position:relative;
  display:block;
  height:calc(42rem/16);
  width:calc(42rem/16);
  margin:0 calc(15rem/16);
  background:url('../../../../images/hexagon-yellow-simple.svg') no-repeat;
  background-size:contain;
  &:before {
    content:'';
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    font-style:normal;
    font-weight:600;
    color: black;
  }

  &__paymentMethod:before {
    content:'!';
    font-size:calc(24rem/16);
  }

  &__reports:before {
    content:'NEW';
    font-size:calc(12rem/16);
  }



  @media #{$phone} {
    margin:0;
    // height:calc(50rem/16);
    // width:calc(50rem/16);
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-height: 2.5rem;
    max-width: 2.5rem;

    &:before {
      transform:translate(-50%,-50%);
    }
  }

}

.CloseNotification {
  cursor:pointer;

  @media #{$tablet} {
    width: calc(15rem / 16);
    height:calc(15rem / 16);
  }
  @media #{$phone}{
    margin:0;
  }
}


.alert_alertBannerOuterDiv__1DoOS button {
  margin: 0 auto;
}

.CloseNotificationButton {
  cursor:pointer;
  display: block;
  padding: calc(10rem / $fontSize);
  border: none;
  background: url('../../../../images/icon-close.svg');
  background-size: contain;
  color: $darkGray;

  
  @media #{$tablet} {
    width: calc(15rem / 16);
    height:calc(15rem / 16);
      padding: calc(5rem / $fontSize);
  }
  
  @media #{$phone}{
    margin:0;
  }
}