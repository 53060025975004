$primaryBlue: #2774AE;
$primaryYellow: #FFD100;
$secondaryDarkBlue: #003B5C;
$secondaryMediumBlue: #005587;
$secondaryBabyBlue: #8BB8E8;
$secondaryLightBlue: #C3D7EE;
$buttonActiveBlue: #DAEBFE;
$guideGray: #CCCCCC;
$darkGray: #666666;
$reallyDarkGray: #333333;
$lightGray: #F6F6F6;
$borderGray: #DDDDDD;
$guideRed: #F54B5E;
$messagingRed: #D0021B;
$errorRed: #D60000;
$messagingGreen: #27A074;
$guideGreen: #48D2A0;
$darkGold: #FFB81C;
$mediumGold: #FFC72C;
