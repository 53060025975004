@import '../../components/sass/theme.module.scss';

.delete-button {
  padding: 0.8rem;
}

.delete-modal {
  z-index:1011;
  position: fixed;
  background-color: white;
  overflow-y: scroll;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 6px 3px 5rem $darkGray;
  top: 40vh;
  height: 20vh;
  min-height: 160px;
  left: 30vw;
  width: 40vw;
  min-width: 420px;

  & > p {
    text-align: center;
  }
}

.delete-button-bottom {
  padding: 0 2rem;
  margin: 0;
}

.delete-button-text {
  color: $messagingRed;
}