@import 'variables';
.continue-as-guest {
  display:block;
  width: 100%;
  text-align: center;

  button {
    background: none;
    font-weight: bold;
    font-size: #{calc(1.5 * $fontSize)}px;
    border: 1px red solid;
    cursor: pointer;
    text-decoration:none;
    margin-top: -1rem;
    margin-bottom: 2rem;
    color:red;
    padding: #{calc($fontSize/2)}px #{($fontSize)}px;

    &:hover, &:focus {
      border: 3px red solid;
    }
  }
}

