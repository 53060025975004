@mixin button-base {
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  font-weight:600;
  text-transform:uppercase;
  text-decoration:none;
  white-space:nowrap;
  vertical-align:middle;
  cursor:pointer;

  &[disabled] {
    background-color: $borderGray;
    color: #FFFFFF;
    border-color: $borderGray;
    cursor:default;

    &:hover {
      background-color: $borderGray;
      color: #FFFFFF;
      border-color: $borderGray;
    }
  }

  > * {
    display:inline-block;
    vertical-align:middle;
    margin:0 0.15rem;
  }

  /* hover, focus state */
  &:hover, &:focus {
    border-color:#000000;
    color: #FFFFFF;
    background-color: #000000;
  }
}

@mixin button-size(
  $size: 'large',
  $square: false
) {
  $defaults: $buttonLarge;

  @if $size == 'small' {
    $defaults: $buttonSmall;
  }

  font-size:#{calc(map-get($defaults,'fontSize') / $fontSize)}rem;
  line-height:#{map-get($defaults,'lineHeight')};
  height:#{calc(map-get($defaults,'buttonHeight') / $fontSize)}rem;

  @if $square {
    width:#{calc( map-get($defaults, 'buttonHeight') / $fontSize)}rem;
  } @else {
    padding:#{calc( map-get($defaults, 'paddingVertical') / $fontSize )}rem #{calc(map-get($defaults, 'paddingHorizontal') / $fontSize )}rem #{calc(map-get($defaults, 'paddingVertical') / $fontSize)}rem;
  }

  /* tablet sizing */
  @media #{$tablet} {
    font-size:#{calc(map-get($buttonSmall, 'fontSize') / $fontSize)}rem;
    line-height:#{map-get($buttonSmall, 'lineHeight')};
    height:#{calc(map-get($buttonSmall, 'buttonHeight') / $fontSize)}rem;

    @if $square {
      width:#{calc( map-get($buttonSmall,'buttonHeight') / $fontSize )}rem;
    } @else {
      padding:#{calc( map-get($buttonSmall,'paddingVertical') / $fontSize )}rem #{calc(
        map-get($buttonSmall,'paddingHorizontal') / $fontSize )}rem #{calc( map-get($buttonSmall,'paddingVertical') / $fontSize )}rem;
    }

  }

  /* mobile sizing */
  @media #{$phone} {
    @if $square {
      width:#{calc( map-get($buttonMobile,'buttonHeight') / $fontSize )}rem;
    } @else {
      padding:#{calc( map-get($buttonMobile,'paddingVertical') / $fontSize )}rem #{calc( map-get($buttonMobile,'paddingHorizontal') / $fontSize )}rem #{calc( map-get($buttonMobile,'paddingVertical') / $fontSize )}rem;
    }
  }
}

@mixin button-color(
  $color: $defaultButtonColors
) {
  border: 1px solid #{map-get($color,'borderColor')};
  color: #{ map-get($color,'color')};
  background-color: #{ map-get($color,'backgroundColor')};
  border-radius:3px;

  /* hover, focus state */
  &:hover, &:focus {
    border-color:#{map-get($color,'borderColorFocus')};
    color: #{map-get($color,'colorFocus')};
    background-color: #{map-get($color,'backgroundColorFocus')};
  }
}

// to be translated
@mixin button-icon(
  $icon,
  $order,
  $glyph
) {
  // ${props =>  (props.icon || props.glyph) && css`
  //   &:${props => props.order && props.order === 'after' ? "after" : "before"} {
  //     display:inline-block;
  //     vertical-align:middle;
  //     height:1.0625rem;
  //     width:1.0625rem;
  //     margin:0 0.15rem;
  //     background-size:contain;
  //     background-repeat:no-repeat;
  //     background-position:50% 50%;
  //
  //     content:${props => props.glyph ? props.glyph : '\'\''};
  //     background-image:${ props => props.icon ? `url("${props.icon}")` : "''"};
  //
  // }`
  // }
}
