@import 'variables';
.container {
  position:relative;
  background: linear-gradient(225.44deg, $primaryBlue 0%, $secondaryDarkBlue 100%);
  width:100%;
  height:#{calc(46 / $fontSize)}rem;
  &:after{
    content:'';
    display:block;
    position:absolute;
    top:#{calc(10 / $fontSize)}rem;
    left:50%;
    transform:translateX(-50%);
    background:url('../../../images/UCLA-logo.svg');
    width: #{calc(79 / $fontSize)}rem;
    height: #{calc(26 / $fontSize)}rem;
  }
  @media #{$phone}{
    &:after{
      display:none;
    }
  }
}
.return-link {
  display:block;
  position:absolute;
  left:.5%;
  top:20%;
  text-decoration:none;
  padding:#{calc(3 / $fontSize)}rem 0 #{calc(3 / $fontSize)}rem #{calc(31 / $fontSize)}rem;
  color:#FFFFFF;

  &:hover, &:focus {
    text-decoration:underline;
    color:#FFFFFF;
  }

  &:before, &:after {
    display:block;
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
  }

  &:before {
    content:'';
    border-radius:50%;
    border:1px solid #FFFFFF;
    height:#{calc(22 / $fontSize)}rem;
    width:#{calc(22 / $fontSize)}rem;
  }

  &:after {
    content:'';
    border-top:1px solid #FFFFFF;
    border-left:1px solid #FFFFFF;
    transform:translate(-20%, -50%) rotate(-45deg);
    height:#{calc(8 / $fontSize)}rem;
    width:#{calc(8 / $fontSize)}rem;
    top:50%;
    left:#{calc(11 / $fontSize)}rem;
  }
}

