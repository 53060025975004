@import '../../../../sass/variables';

.link {
  
  &-toitem {
    margin: -1rem 0 0 0;
    padding: 0 0.5rem;
    position: relative;
    text-align: right;
    // right: 4px;
  }
}