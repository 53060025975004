@import 'variables';

.level {
  margin:#{calc((48 / $fontSize))}rem 0;

  @media #{$phone} {
    margin:#{calc(38 / $fontSize)}rem 0 #{calc(38 / $fontSize)}rem;
    font-size:#{calc(21 / $fontSize)}rem;

    em {
      font-size:1rem;
    }

    button {
      display:flex;
      width:100%;
      margin-bottom:#{calc(16 / $fontSize)}rem;
    }
  }
}

.cards {
  margin-top:#{calc(48 / $fontSize)}rem;

  @media #{$phone} {
    margin-top:#{calc(16 / $fontSize)}rem;
  }

  > * {
    margin-right:#{calc(48 / $fontSize)}rem;

    @media #{$phone} {
      margin-right:0;
    }
  }
}
