@import '../../sass/variables';
// @import './colors';

.moreInfoPosition{
  display: inline-block;
  margin: calc(4rem / $fontSize);
  margin-bottom: 0;
  height:1rem;
  width:1rem;
}
