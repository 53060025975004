@import '../../components/sass/theme.module.scss';

.admin-container {
  margin: 1rem;
  table {
    border: 1px solid $guideGray;
    border-spacing: 0;
    border-radius: 4px;
  }
  td {
    border: 1px $borderGray solid;
    margin: 1px;
    padding: 5px;
    text-align: right;
  }
}

.admin-auction-page{
  
  &-tabnav {
    width: fit-content;
    // max-width: 852px;
    display: block;
    margin: 0 auto 3rem;
    padding-bottom: 2rem;
    background-color: #fafafa;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    & > a, & > button {
      font-size: 3rem;
      color: $primaryBlue;
      font-weight: 700;
      max-width: 50%;
      cursor: pointer;

      &:hover {
        color: $secondaryMediumDarkBlue;
      }
    }

    //styled to look like a link due to props.history needing to navigate outside of admin
    &-button {
      border: none;
      background-color: transparent;

    }
  }
  &-tab {
    

    &-header {
    font-size: 1.5rem;
    color: $primaryBlue;
    font-weight: 700;
    margin: 0;
    padding: 1.5rem;
    }

    &-bolded {
      font-weight: 700;
    }
  }
  &-breadcrumbs {
    margin-top: 1rem;
    & > span {
      font-weight: 800;
    }
  }
}

.test-auction {
  &-page {
    width: 50rem;
    margin: 0 auto;
    display: flex;
    padding-bottom: 3rem;
  }
  &-container {
    border-radius: 3px;
    box-shadow: 3px 2px 1rem $darkGray;
    width: 22rem;
    height: 38rem;
    overflow: scroll;
  }
  &-buttons {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;

    & > h4 {
      font-weight: 700;
      padding: 0;
      margin: 0 auto;
    }
  }

  &-iframe {
    border-radius: 5px;
    border: 1px solid $guideGray;
    box-shadow: 2px 1px 10px $darkGray;
    
  }
}



//solicitation auction details
// .solicitation-detail-row {
//   display: flex;
//   flex-direction: row;
//   margin: 0;
//   padding: 0;
//   border-bottom: 1px solid black;

//   & > h4 {
//     font-weight: 700;
//     font-size: 1rem;
//     width: 12rem;
//   }
//   & > p {
//     font-size: 0.9rem;
//     padding: 0.3rem;
//     border: 1px $guideGray solid;
//     border-radius: 3px;
//   }
// }

//auction and item pages

.row-container {
  margin-right: 1rem;
  position: relative;
}

.admin-auction-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
  border: 1px solid $guideGray;
}

.admin-auction-row:hover {
  background-color: $lightGray;
  border: 1px solid $guideGray;
}

.admin-auction-button-container {
  position: absolute;
  top: 2px;
  left: calc(100% - 6rem);
  display: flex;
  align-items: center;
  & > button {
    margin: 0.5rem;
  }
}

.button-create {
  margin-bottom: 2rem;
}

.button-edit {
  padding: 0.8rem;
}


.auction-modal {
  top: 20vh;
  height: 60vh;
  left: 20vw;
  width: 60vw;
  z-index:1011;
  position: fixed;
  background-color: white;
  overflow-y: scroll;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 6px 3px 5rem $darkGray;
}

.item-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.auction-select-list {
  list-style: none;
}

.auction-headers {
  font-weight: 700;
  color: $secondaryMediumDarkBlue;
}

.auction-blocks {
  margin-bottom: 4rem;
}

//preview publish widget 

.widget{
  
  &-container {
    width: fit-content;
    padding: 1rem;
    box-shadow: 2px 2px 0.5rem $guideGray;
    margin-bottom: 2rem;
  }

  &-status-container {
    display: flex;
    align-items: center;
    & > * {
      margin: 1rem;
    }
  }
  &-button-container {
    & > * {
      margin: 1rem;
    }
  }
  &-checklist {
    
    &-header, &-card, &-widget {
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
    }
    &-header {
      font-weight: 700;
    }
    &-card, &-widget {
      list-style: none;
      display: flex;
      
      & > * {
        padding-right: 0.5rem;
      }

      &-item {
        display: flex;
        padding: 0.2rem;

        & > span {
          padding-right: 1rem;
        }
      }
    }

    &-widget {
      flex-direction: column;
    }

    &-card {
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
  }
  &-solicitation {
    width: 50rem;
    margin: 2rem auto;
  }
}

.solicitation-input-container {
  margin: 0.5rem 1rem;

  & > * {
    margin: 0;
    padding: 0;
  }

  & > p {
    font-weight: 700;

    & > span {
      color: $messagingRed;
      padding-left: 1rem;
    }

    & > .Submitted {
      color: $primaryBlue;
    }

    & > .Approved {
      color: $messagingGreen;
    }
  }
}

.started-button-container {
  display: flex;
}

.flex-centered-spaced-container {
  display: flex;
  align-items: center;

  & > * {
    margin: 1rem;
  }
}

.extend-button-container {
  & > button {
    margin-right: 2rem;
    padding: 1rem;
  }
}

//status alert

.status-alert {
  background-color: $darkGold;
  padding: 0.3rem 0.7rem;
  font-weight: 700;
  font-size: 0.7rem;
  width: fit-content;
  color: black;
  margin: 0 auto;

  &-green {
    background-color: $primaryDarkGreen;
    color: white;
  }
}

// alert for edit ability inside auction details page

.details-alert {
  &-container {
    margin: 0 0 2rem;
    border-top: 4px solid $invalidCardOrange;
    max-width: 50rem;
    box-shadow: 2px 2px 0.5rem $guideGray;
    display: flex;


    & > img {
      width: 1rem;
      margin: 1rem;
    }
  }
  &-header, &-message {
    margin: 0.5rem;
    padding: 0;
  }

  &-header {
    font-weight: 700;
    font-size: 1.2rem;
  }
  &-message {
    font-size: 0.9rem;
  }
}

.redx {
  color: $messagingRed;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.greencheck {
  color: $messagingGreen;
  font-weight: 700;
  margin: 0;
  padding: 0;
}