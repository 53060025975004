@import 'variables';

// custom phone breakpoint
$explorePhone: "screen and (max-width: 850px)";

.wrapper {
  @media #{$explorePhone} {
    margin-bottom:#{calc(-10 / $fontSize)}rem;
  }
}

.explore-links {
  position:relative;
  list-style-type:none;
  padding:0;
  margin:#{calc(60 / $fontSize)}rem 0;
  display:flex;

  @media #{$explorePhone} {
    display:block;
    margin:0;
  }

  li {
    display:flex;
    flex:1 1 percentage(calc(1 / 3));
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    list-style-type:none;
    padding:#{calc(5 / $fontSize)}rem #{calc(30 / $fontSize)}rem;
    margin:0;

    @media #{$explorePhone} {
      padding:0 0 #{calc(10 / $fontSize)}rem;
      button {
        width:100%;
      }
    }

    &:before {
      content:'';
      display:block;
      background-repeat:no-repeat;
      background-size:contain;
    }


  }

  &:before, &:after {
    content:'';
    display:block;
    position:absolute;
    height:100%;
    width:0;
    border:2px solid $primaryBlue;
    border-radius:2px;

    @media #{$explorePhone} {
      display:none;
    }
  }

  &:before {
    left:33.33%;
  }
  &:after {
    right:33.33%;
  }
}

.copy {
  text-align:center;
  margin-top:#{calc(24 / $fontSize)}rem;

  @media #{$explorePhone} {
    margin-top:0;
  }
}

.career-programs {
  &:before {
    background-image:url('../images/alumni-careers.png');
    width:#{calc(183 / $fontSize)}rem;
    height:#{calc(182 / $fontSize)}rem;
  }
}
.alumni-networks {
  &:before {
    background-image:url('../images/alumni-networks.png');
    width:#{calc(183 / $fontSize)}rem;
    height:#{calc(182 / $fontSize)}rem;

    @media #{$explorePhone} {
      margin-bottom:#{calc(10 / $fontSize)}rem;
    }
  }
}
.alumni-events {
  &:before {
    background-image:url('../images/alumni-events.svg');
    width:#{calc(141 / $fontSize)}rem;
    height:#{calc(182 / $fontSize)}rem;
    @media #{$explorePhone} {
      margin-bottom:#{calc(16 / $fontSize)}rem;
    }
  }

}
