@import '../../sass/variables.scss';

.successful-update-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.white-check-icon{
    width: 4rem;
    height: 4rem;
    padding: 1.5rem;
    border-radius: 100px;
    background-color: $primaryDarkGreen;
    margin: 1.5rem;
}
.successful-update-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;    
    width: 18rem;

    & > button {
        margin-top: 2rem;
    }
}

@media #{$phone} {
    .successful-update-body {
        & > h1 {
            font-size: 2rem;
        }
    }
    .green-check-icon{
        width: 2.5rem;
        height: 2.5rem;
        padding: 1rem;
    }
    .successful-update-buttons {        
        width: 90%;
    
        & > button {
            margin-top: 1rem;
        }
    }
}