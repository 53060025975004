@import '../../sass/variables.scss';

.p-zero-spacing {
    margin: 0;
    padding: 0;
}
.hidden {
    display: none;
}
.warning-icon {
    width: #{calc(20 / $fontSize)}rem;
}
.payment-method-body {
    margin: 0 3rem;

    &__copy{
        margin-bottom: 2rem;
    }
}

.payment-method-body-top {
    display: flex;
    align-items: center;

    //button link positioning
    & > button {
        margin-left: 3rem;
    }
}

.payment-method{
    border: 1.5px solid $darkGray;
    border-radius: 5px;
    padding: 3rem 0 2rem 4rem;
    margin-bottom: 1rem;
}

.card-invalid {
    &-warning {
        padding-top: #{calc(20 / $fontSize)}rem;
        display: flex;
        font-weight: 700;
        font-size:#{calc(14 / $fontSize)}rem;

        & p {
            padding-left: 2rem;
        }
    }
}

@media #{$phone} {

    .payment-method-header {
        padding-left: 1rem;
    }

    .payment-method-body {
        margin: 0;
        margin-left: 0;

        &__copy {
            padding: 0 1rem;
        }
    }
    .payment-method-body-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        //button link positioning
        & > button {
            margin-top: 1rem;
            margin-left: 0;
        }
    }
    .payment-method {
        border: 1.5px solid $darkGray;
        border-radius: 5px;
        padding: 1rem;
        margin-bottom: 1rem;
    }
    //CARD IMAGE FOR EACH PAYMENT METHOD 
    .card-invalid {
        &-warning {
            width: fit-content;
            margin: 0 auto;
            font-size: 1rem;

            & p {
                padding-left: 1rem;
            }
        }
    }   
}